import axios from 'axios';

export const get_permanent_contact_by_mooring_id = (
   mooring_id,
   callback,
   errorcallback,
) => {
   axios
      .get(`/assigned_contacts/get_by_mooring_id?id=${mooring_id}`)
      .then((response) => {
         if (response.status == 200) {
            callback(response.data);
         }
      })
      .catch((err) => {
         errorcallback(err);
      });
};

export function createAssignedContact(
   assigned_contact,
   callback,
   errorCallback,
) {
   axios
      .post('/assigned_contacts/add', assigned_contact)
      .then((res) => {
         const response = res.data;
         callback(response);
      })
      .catch((error) => {
         errorCallback(error);
      });
}

export function updateAssignedContact(
   assigned_contact,
   callback,
   errorCallback,
) {
   axios
      .post('/assigned_contacts/update', assigned_contact)
      .then((res) => {
         const response = res.data;
         callback(response);
      })
      .catch((error) => {
         errorCallback(error);
      });
}

export function deleteAssignedContact(id, callback, errorCallback) {
   axios
      .post('/assigned_contacts/delete', { id: id })
      .then((res) => {
         const response = res.data;
         callback(response);
      })
      .catch((error) => {
         errorCallback(error);
      });
}

export function getMyContactTypes(callback, errorcallback) {
   axios
      .post('contacts/get_my_contact_types')
      .then((response) => {
         if (response.status == 200) {
            var contact_types = response.data.contact_types;
            callback(contact_types);
         }
      })
      .catch((err) => {
         errorcallback(err.response);
      });
}

export function updateContactTypes(contact_types, callback, errorcallback) {
   axios
      .post('contacts/update_contact_types', contact_types)
      .then((response) => {
         if (response.status == 200) {
            callback();
         }
      })
      .catch((err) => {
         errorcallback(err.response);
      });
}
