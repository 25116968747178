import React from 'react';
import { useLocation } from 'react-router-dom';

export const withSearchParams = (Component) => {
   const WithSearchParams = (props) => {
      const search = useLocation().search;
      const searchParams = new URLSearchParams(search);

      return <Component searchParams={searchParams} {...props} />;
   };

   WithSearchParams.displayName = `WithSearchParams(${getDisplayName(Component)})`;

   return WithSearchParams;
};

function getDisplayName(Component) {
   return Component.displayName || Component.name || 'Component';
}
