import React from 'react';
import { Button, Col, Container, Row } from 'react-bootstrap';
import { withTranslation } from 'react-i18next';

// import { getContactTypes } from '../actions/contacts';
import {
   createMooring,
   deleteMooring,
   getCountryCode,
   getMyMooring,
   getMyMooringTypes,
   getMyMooringZones,
   // transferMooring,
   updateMooring,
} from '../actions/moorings';
import CountrySelector from '../components/CountrySelector';
import DocumentMaintainArea from '../components/DocumentMaintainArea';
import FormInput from '../components/FormInput';
import FormSegmentedControl from '../components/FormSegmentedControl';
import StatusAlerts from '../components/StatusAlerts';
import TransferMooringSection from '../components/TransferMooringSection';
import COLORS from '../constants/colors';
import { CURRENCIES } from '../constants/constants';
import globalStyles from '../stylesheets/globalStyles';
import { errorsExist, validateCreateMooringForm } from '../utils/validators';

const getTextAreaHeight = (text) => {
   if (!text) return 1;
   return text.split('\n').length;
};

class EditMooring extends React.Component {
   constructor(props) {
      super(props);
      this.state = {
         loading: false,
         success: false,
         error: false,
         mooring: props.mooring,
         create: !!props.mooring.create,
         rowsAdditionalDescription: 1,
         rowsConfidentialDescription: 1,
         rowsMarinaNotes: 1,
         mooring_types: [],
         mooring_zones: [],
         mooring_renter_types: [],
         transfer_email: '',
      };
   }

   componentDidMount() {
      this.props.onRef(this);

      this.setState({
         rowsAdditionalDescription:
            getTextAreaHeight(this.state.mooring.additional_description) || 1,
         rowsConfidentialDescription:
            getTextAreaHeight(this.state.mooring.confidential_description) || 1,
      });

      //Automatically add country from API
      if (this.props.mooring.create) {
         getCountryCode(
            this.state.mooring.latitude,
            this.state.mooring.longitude,
            (country) => {
               this.setState({
                  mooring: { ...this.state.mooring, country: country },
               });
            },
            () => {},
         );
      }

      getMyMooringTypes(
         (mooring_types) => {
            this.setState({ mooring_types: mooring_types });
         },
         () => {},
      );
      getMyMooringZones(
         (mooring_zones) => {
            this.setState({ mooring_zones: mooring_zones });
         },
         () => {},
      );
   }

   componentWillUnmount() {
      this.props.onRef(undefined);
   }

   refreshMooring = () => {
      console.log('REfreshing editMOoringScreen');
      getMyMooring(
         (mooring) => {
            this.setState({ mooring: mooring });
         },
         () => {},
      );
   };

   handleUpdateMooringClick = () => {
      if (errorsExist(validateCreateMooringForm(this.state))) {
         this.setState({
            error: true,
            errorMessage: this.props.t('errors.someValuesAreNotCorrect'),
         });
      } else {
         var mooring = this.state.mooring;
         this.setState({ loading: true, success: false, error: false }, () => {
            updateMooring(
               this.state.mooring,
               this.updateMooringSucces,
               this.updateMooringFail,
            );
         });
      }
   };

   changeMooringPosition = (position) => {
      console.log('changeMooringPosition', position);
      this.setState({
         mooring: {
            ...this.state.mooring,
            latitude: position.latitude.toFixed(6),
            longitude: position.longitude.toFixed(6),
         },
      });
   };

   updateMooringSucces = (response) => {
      this.setState({ loading: false, success: true, fail: false });

      if (this.state.create) {
         this.props.createSuccess();
         this.props.backToMooringDetails(response.mooring);
      } else {
         this.props.updateSuccess();
         this.props.backToMooringDetails(this.state.mooring);
      }
   };

   updateMooringFail = (err) => {
      this.setState({ loading: false, error: true }, () => {
         const error_code = err.response.data.error_code;
         if (error_code == '888') {
            // Blocked area
            const blocked_area = err.response.data.blocked_area;
            window.confirm(
               this.props.t(`EditMooring.AreaBlocked`, {
                  contact_email: blocked_area.contact_email,
               }),
            ) && this.setState({ loading: false, error: true });
         }
      });

      console.log('err.response.data', err.response.data);
   };

   handleCreateMooringClick = () => {
      if (errorsExist(validateCreateMooringForm(this.state))) {
         this.setState({
            error: true,
            errorMessage: this.props.t('errors.someValuesAreNotCorrect'),
         });
      } else {
         this.setState({ loading: true, success: false, fail: false }, () => {
            createMooring(
               this.state.mooring,
               this.updateMooringSucces,
               this.updateMooringFail,
            );
         });
      }
   };

   handleDeleteClick = () => {
      window.confirm(this.props.t(`EditMooring.areYouSureDeleteMooring`)) &&
         deleteMooring(
            this.state.mooring.mooring_id,
            () => {
               this.props.deleteSuccess();
            },
            (err) => {
               console.log('error', err);
               this.setState({ error: true });
            },
         );
   };

   render() {
      const { t } = this.props;
      const { mooring } = this.state;
      console.log('in editScreen', mooring);
      const app_visible = Boolean(mooring.app_visible);

      const NoOrYes = t('global.NoOrYes');
      const MonthNames = t('global.monthNames');

      const role = localStorage.getItem('role');
      const management = role == 'management';

      const readOnly = mooring.rights ? !mooring.rights.edit : false;

      const formErrors = validateCreateMooringForm(this.state);
      console.log('validateCreateMooringForm', formErrors);

      return (
         <div
            style={{
               ...globalStyles.leftHalfScreenContainer,
               backgroundColor: 'white',
               position: 'relative',
               overflowY: 'hidden',
            }}>
            {/* <h3>{t('EditMooring.availabilities')}</h3>
        {this.props.mooring.available_periods.map(period => (
          <div key={period.from_date}>{`From: ${period.from_date} To: ${period.to_date}`}</div>
        ))} */}

            <div style={{ ...globalStyles.section }}>
               <Row>
                  <Col md={8}>
                     <h3>Details</h3>
                  </Col>
                  <Col md={4}>
                     {!this.state.create && mooring.rights.edit && (
                        <Button
                           style={globalStyles.saveButton}
                           disabled={this.state.loading}
                           onClick={() => this.handleUpdateMooringClick()}>
                           {t('global.save')}
                        </Button>
                     )}
                     {this.state.create && (
                        <Button
                           style={globalStyles.saveButton}
                           disabled={this.state.loading}
                           onClick={() => this.handleCreateMooringClick()}>
                           {t('global.save')}
                        </Button>
                     )}
                  </Col>
               </Row>
            </div>

            <StatusAlerts
               state={this.state}
               errorMessage={this.state.errorMessage}
            />

            <Container
               style={{
                  backgroundColor: 'white',
                  height: '100%',
                  overflowY: 'scroll',
                  boxShadow: 'none',
               }}>
               <FormInput
                  label={t('EditMooring.harbourName')}
                  type='text'
                  value={this.state.mooring.harbour_name}
                  error={formErrors.harbour_name}
                  onChange={(text) => {
                     this.setState({
                        mooring: {
                           ...this.state.mooring,
                           harbour_name: text.target.value,
                        },
                     });
                  }}
                  readOnly={readOnly}
               />

               <FormInput
                  label={t('EditMooring.berthNo')}
                  type='text'
                  value={this.state.mooring.berth_number}
                  error={formErrors.berth_number}
                  onChange={(text) => {
                     this.setState({
                        mooring: {
                           ...this.state.mooring,
                           berth_number: text.target.value,
                        },
                     });
                  }}
                  readOnly={readOnly}
               />

               <FormInput
                  type='text'
                  label={t('EditMooring.coordinates')}
                  placeholder='coordinates'
                  value={`${mooring.latitude} , ${mooring.longitude}`}
                  onClick={() => {
                     this.props.changeMooringPositionClicked(
                        this.state.mooring,
                     );
                  }}
                  readOnly
               />

               <CountrySelector
                  label={t('EditMooring.country')}
                  value={this.state.mooring.country}
                  onChange={(value) => {
                     this.setState({
                        mooring: { ...this.state.mooring, country: value },
                     });
                  }}
               />

               <FormSegmentedControl
                  label={t('EditMooring.appVisible')}
                  selected={this.state.mooring.app_visible}
                  error={formErrors.app_visible}
                  variant='base'
                  segments={NoOrYes}
                  onChangeSegment={(index) =>
                     this.setState({
                        mooring: { ...this.state.mooring, app_visible: index },
                     })
                  }
               />

               <FormInput
                  label={t('EditMooring.maxLength')}
                  type='number'
                  unit='m'
                  value={this.state.mooring.max_length}
                  error={formErrors.max_length}
                  onChange={(text) => {
                     this.setState({
                        mooring: {
                           ...this.state.mooring,
                           max_length: text.target.value,
                        },
                     });
                  }}
                  step={0.01}
                  readOnly={readOnly}
               />

               <FormInput
                  label={t('EditMooring.maxBeam')}
                  type='number'
                  unit='m'
                  value={this.state.mooring.max_beam}
                  error={formErrors.max_beam}
                  onChange={(text) => {
                     this.setState({
                        mooring: {
                           ...this.state.mooring,
                           max_beam: text.target.value,
                        },
                     });
                  }}
                  step={0.01}
                  readOnly={readOnly}
               />

               <FormInput
                  label={t('EditMooring.maxDraught')}
                  type='number'
                  unit='m'
                  value={this.state.mooring.max_draught}
                  error={formErrors.max_draught}
                  onChange={(text) => {
                     this.setState({
                        mooring: {
                           ...this.state.mooring,
                           max_draught: text.target.value,
                        },
                     });
                  }}
                  step={0.01}
                  readOnly={readOnly}
               />

               <FormInput
                  label={t('EditMooring.maxWeight')}
                  type='number'
                  unit='kg'
                  value={this.state.mooring.max_weight}
                  onChange={(text) => {
                     this.setState({
                        mooring: {
                           ...this.state.mooring,
                           max_weight: text.target.value,
                        },
                     });
                  }}
                  step={1}
                  readOnly={readOnly}
               />

               <FormInput
                  label={t('EditMooring.real_length')}
                  type='number'
                  unit='m'
                  value={this.state.mooring.real_length}
                  onChange={(text) => {
                     this.setState({
                        mooring: {
                           ...this.state.mooring,
                           real_length: text.target.value,
                        },
                     });
                  }}
                  step={0.01}
                  readOnly={readOnly}
               />

               <FormInput
                  label={t('EditMooring.real_beam')}
                  type='number'
                  unit='m'
                  value={this.state.mooring.real_beam}
                  onChange={(text) => {
                     this.setState({
                        mooring: {
                           ...this.state.mooring,
                           real_beam: text.target.value,
                        },
                     });
                  }}
                  step={0.01}
                  readOnly={readOnly}
               />

               <FormInput
                  label={t('EditMooring.pierHeight')}
                  type='number'
                  unit='m'
                  value={this.state.mooring.pier_height}
                  onChange={(text) => {
                     this.setState({
                        mooring: {
                           ...this.state.mooring,
                           pier_height: text.target.value,
                        },
                     });
                  }}
                  step={0.01}
                  readOnly={readOnly}
               />

               {management && (
                  <FormInput
                     as='select'
                     label={t('EditMooring.mooringType')}
                     type='text'
                     value={this.state.mooring.mooring_type}
                     onChange={(text) => {
                        this.setState({
                           mooring: {
                              ...this.state.mooring,
                              mooring_type: text.target.value,
                           },
                        });
                     }}>
                     {this.state.mooring_types.map((mooring_type) => (
                        <option
                           key={mooring_type.id}
                           value={mooring_type.mooring_type}>
                           {mooring_type.mooring_type}
                        </option>
                     ))}
                  </FormInput>
               )}

               {management && (
                  <FormInput
                     as='select'
                     label={t('EditMooring.mooringZone')}
                     type='text'
                     value={this.state.mooring.mooring_zone}
                     onChange={(text) => {
                        this.setState({
                           mooring: {
                              ...this.state.mooring,
                              mooring_zone: text.target.value,
                           },
                        });
                     }}>
                     <option key={0} value={''}>
                        {' '}
                     </option>
                     {this.state.mooring_zones.map((mooring_zone) => (
                        <option key={mooring_zone.id} value={mooring_zone.zone}>
                           {mooring_zone.zone}
                        </option>
                     ))}
                  </FormInput>
               )}
               <FormSegmentedControl
                  label={t('EditMooring.power')}
                  selected={this.state.mooring.power}
                  error={formErrors.power}
                  variant='base'
                  segments={NoOrYes}
                  onChangeSegment={(index) =>
                     this.setState({
                        mooring: { ...this.state.mooring, power: index },
                     })
                  }
                  readOnly={readOnly}
               />

               <FormSegmentedControl
                  label={t('EditMooring.water')}
                  selected={this.state.mooring.water}
                  error={formErrors.water}
                  variant='base'
                  segments={NoOrYes}
                  onChangeSegment={(index) =>
                     this.setState({
                        mooring: { ...this.state.mooring, water: index },
                     })
                  }
                  readOnly={readOnly}
               />

               <FormSegmentedControl
                  label={t('EditMooring.buoy')}
                  selected={this.state.mooring.buoy}
                  error={formErrors.buoy}
                  variant='base'
                  segments={NoOrYes}
                  onChangeSegment={(index) =>
                     this.setState({
                        mooring: { ...this.state.mooring, buoy: index },
                     })
                  }
                  readOnly={readOnly}
               />

               {app_visible && (
                  <FormInput
                     as='textarea'
                     rows={this.state.rowsAdditionalDescription}
                     label={t('EditMooring.additionalDescription')}
                     type='textarea'
                     value={this.state.mooring.additional_description}
                     onChange={(text) => {
                        this.setState({
                           mooring: {
                              ...this.state.mooring,
                              additional_description: text.target.value,
                           },
                           rowsAdditionalDescription: getTextAreaHeight(
                              text.target.value,
                           ),
                        });
                     }}
                     readOnly={readOnly}
                  />
               )}
               {app_visible && (
                  <FormInput
                     as='textarea'
                     rows={this.state.rowsConfidentialDescription}
                     label={t('EditMooring.confidentialDescription')}
                     type='text'
                     value={this.state.mooring.confidential_description}
                     onChange={(text) => {
                        this.setState({
                           mooring: {
                              ...this.state.mooring,
                              confidential_description: text.target.value,
                           },
                           rowsConfidentialDescription: getTextAreaHeight(
                              text.target.value,
                           ),
                        });
                     }}
                     readOnly={readOnly}
                  />
               )}

               <FormInput
                  as='textarea'
                  rows={this.state.rowsMarinaNotes}
                  label={t('EditMooring.marinaNotes')}
                  type='text'
                  value={this.state.mooring.marina_notes}
                  onChange={(text) => {
                     this.setState({
                        mooring: {
                           ...this.state.mooring,
                           marina_notes: text.target.value,
                        },
                        rowsMarinaNotes: getTextAreaHeight(text.target.value),
                     });
                  }}
                  readOnly={readOnly}
               />

               {app_visible && (
                  <div>
                     <h3> {t('EditMooring.settings')}</h3>
                     <FormSegmentedControl
                        label={t('EditMooring.manualConfirmation')}
                        selected={this.state.mooring.needs_booking_confirmation}
                        variant='base'
                        segments={NoOrYes}
                        onChangeSegment={(index) =>
                           this.setState({
                              mooring: {
                                 ...this.state.mooring,
                                 needs_booking_confirmation: index,
                              },
                           })
                        }
                        readOnly={readOnly}
                     />
                     <FormInput
                        label={t('EditMooring.maxNights')}
                        type='number'
                        value={this.state.mooring.max_nights}
                        onChange={(text) => {
                           this.setState({
                              mooring: {
                                 ...this.state.mooring,
                                 max_nights: text.target.value,
                              },
                           });
                        }}
                        step={1}
                        readOnly={readOnly}
                     />

                     {/* <FormInput
                label={t('EditMooring.maxNightsPerYear')}
                type="number"
                value={this.state.mooring.max_nights_per_year}
                onChange={(text) => {
                  this.setState({
                    mooring: { ...this.state.mooring, max_nights_per_year: text.target.value },
                  });
                }}
                step={1}
                readOnly={readOnly}
              /> */}

                     <FormInput
                        label={t('EditMooring.checkInTime')}
                        type='time'
                        value={this.state.mooring.check_in_time}
                        onChange={(text) => {
                           this.setState({
                              mooring: {
                                 ...this.state.mooring,
                                 check_in_time: text.target.value,
                              },
                           });
                        }}
                        step={1}
                        readOnly={readOnly}
                     />

                     <FormInput
                        label={t('EditMooring.checkOutTime')}
                        type='time'
                        value={this.state.mooring.check_out_time}
                        onChange={(text) => {
                           this.setState({
                              mooring: {
                                 ...this.state.mooring,
                                 check_out_time: text.target.value,
                              },
                           });
                        }}
                        step={1}
                        readOnly={readOnly}
                     />
                  </div>
               )}

               <h3> {t('EditMooring.occupiedBy')}</h3>
               <FormInput
                  label={t('EditMooring.boatName')}
                  type='text'
                  value={this.state.mooring.home_boat_name}
                  onChange={(text) => {
                     this.setState({
                        mooring: {
                           ...this.state.mooring,
                           home_boat_name: text.target.value,
                        },
                     });
                  }}
                  readOnly={readOnly}
               />
               <FormInput
                  label={t('EditMooring.port')}
                  type='text'
                  value={this.state.mooring.home_boat_port_of_registry}
                  onChange={(text) => {
                     this.setState({
                        mooring: {
                           ...this.state.mooring,
                           home_boat_port_of_registry: text.target.value,
                        },
                     });
                  }}
                  readOnly={readOnly}
               />
               {app_visible && (
                  <div>
                     <h3> {t('EditMooring.finance')}</h3>
                     <FormSegmentedControl
                        label={t('MaintainTimesAndPrices.feeIncluded')}
                        segments={NoOrYes}
                        selected={this.state.mooring.harbor_fee_included}
                        error={formErrors.harbor_fee_included}
                        onChange={(index) =>
                           this.setState({
                              mooring: {
                                 ...this.state.mooring,
                                 harbor_fee_included: index,
                              },
                           })
                        }
                        readOnly={readOnly}
                     />
                     <FormSegmentedControl
                        label={t('MaintainTimesAndPrices.currency')}
                        segments={CURRENCIES}
                        onChange={(index) =>
                           this.setState({
                              mooring: {
                                 ...this.state.mooring,
                                 payment_currency: CURRENCIES[index],
                              },
                           })
                        }
                        selected={CURRENCIES.indexOf(
                           this.state.mooring.payment_currency,
                        )}
                        error={formErrors.payment_currency}
                        readOnly={readOnly}
                     />

                     {/* CURRENCIES.indexOf(this.state.payment_currency) */}

                     <FormInput
                        label={t('MaintainTimesAndPrices.defaultPrice')}
                        type={mooring.custom_price ? 'text' : 'number'}
                        value={
                           mooring.custom_price
                              ? 'custom'
                              : mooring.daily_rate_default
                        }
                        error={formErrors.daily_rate_default}
                        onChange={(text) => {
                           this.setState({
                              mooring: {
                                 ...this.state.mooring,
                                 daily_rate_default: text.target.value,
                              },
                           });
                        }}
                        readOnly={readOnly}
                        disabled={mooring.custom_price}
                     />
                  </div>
               )}

               {management && (
                  <>
                     {this.state.mooring.yearly_rates && (
                        <h3> {t('EditMooring.yearlyRates')}</h3>
                     )}
                     {this.state.mooring.yearly_rates &&
                        this.state.mooring.yearly_rates.map(
                           (yearly_rate, index) => (
                              <FormInput
                                 key={yearly_rate.id}
                                 label={`${yearly_rate.type}`}
                                 unit={this.state.mooring.payment_currency}
                                 error={formErrors.payment_currency}
                                 type='number'
                                 value={yearly_rate.rate}
                                 onChange={(e) => {
                                    var yearly_rates =
                                       this.state.mooring.yearly_rates;
                                    yearly_rates[index].rate = e.target.value;
                                    this.setState({
                                       mooring: {
                                          ...mooring,
                                          yearly_rates: yearly_rates,
                                       },
                                    });
                                 }}
                              />
                           ),
                        )}

                     {this.state.mooring.operating_costs && (
                        <h3> {t('EditMooring.operatingCosts')}</h3>
                     )}
                     {this.state.mooring.operating_costs &&
                        this.state.mooring.operating_costs.map(
                           (period, index) => (
                              <FormInput
                                 key={period.id}
                                 label={`${MonthNames[period.from_month - 1]} - ${MonthNames[period.to_month - 1]}`}
                                 unit={this.state.mooring.payment_currency}
                                 type='number'
                                 value={period.period_cost}
                                 onChange={(e) => {
                                    var operating_costs =
                                       this.state.mooring.operating_costs;
                                    operating_costs[index].period_cost =
                                       e.target.value;
                                    this.setState({
                                       mooring: {
                                          ...mooring,
                                          operating_costs: operating_costs,
                                       },
                                    });
                                 }}
                              />
                           ),
                        )}

                     <FormInput
                        label={t('EditMooring.loan')}
                        type='number'
                        unit={mooring.payment_currency}
                        value={this.state.mooring.loan}
                        onChange={(text) => {
                           this.setState({
                              mooring: {
                                 ...this.state.mooring,
                                 loan: text.target.value,
                              },
                           });
                        }}
                        step={0.01}
                        readOnly={readOnly}
                     />
                  </>
               )}

               <DocumentMaintainArea
                  id_params={{ mooring_id: this.state.mooring.mooring_id }}
               />

               {this.state.mooring.mooring_id && (
                  <>
                     {mooring.rights.transfer && (
                        <>
                           <TransferMooringSection
                              mooring={this.state.mooring}
                           />
                        </>
                     )}
                     <div
                        style={{
                           width: '100%',
                           height: 1,
                           backgroundColor: COLORS.GRAY_OUTLINE,
                           marginBottom: 20,
                        }}></div>
                     {!this.state.create && mooring.rights.delete && (
                        <Button
                           style={{
                              ...globalStyles.normalButton,
                              color: 'red',
                              width: '100%',
                           }}
                           onClick={this.handleDeleteClick}>
                           {t('MaintainMoorings.delete')}
                        </Button>
                     )}
                  </>
               )}

               <div style={{ height: '130px' }}></div>
            </Container>
         </div>
      );
   }
}

export default withTranslation()(EditMooring);
