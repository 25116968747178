// If a mooring is bigger than HIGH_MOORING_BOAT_RATIO x Boat (in all dimensions), a warning popup appears
export const HIGH_MOORING_BOAT_RATIO = 1.3;
export const COMMUNICATION_LANGUAGES = ['EN', 'DE', 'IT', 'FR', 'ES', 'DA'];
export const CURRENCIES = ['CHF', 'EUR', 'USD', 'GBP', 'DKK'];
export const ROLES = ['Private', 'Marina', 'Management'];

// Mooring availabilities
export const available = 'available';
export const available_day_by_day = 'available_day_by_day';
export const booked = 'booked';
export const unavailable = 'unavailable';
