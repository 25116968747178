import '../stylesheets/calendar.css';

import moment from 'moment';
import React from 'react';
import { Button, Collapse } from 'react-bootstrap';
import Calendar from 'react-calendar';
import { withTranslation } from 'react-i18next';

import { getBookingDetails } from '../actions/bookings';
import { getBoatDocumentToken } from '../actions/documents';
import FormInput from '../components/FormInput';
import PdfModal from '../components/PdfModal';
// import COLORS from '../constants/colors';
import { DATE_FORMAT } from '../constants/format';
import { getTextAreaHeight } from '../utils/utilities';
import CancelButton from './CancelButton';

const getDaysBetweenPeriod = (startDate, stopDate) => {
   var dateArray = [];
   var currentDate = moment(startDate);
   stopDate = moment(stopDate);

   if (currentDate > stopDate) {
      [currentDate, stopDate] = [stopDate, currentDate];
   }

   while (currentDate < stopDate) {
      dateArray.push(moment(currentDate).format('YYYY-MM-DD'));
      currentDate = moment(currentDate).add(1, 'days');
   }
   return dateArray;
};

class BookingCalendar extends React.Component {
   constructor(props) {
      super(props);

      this.state = {
         selected_start: '',
         selected_end: '',
         selecting: false,
         bookingDetails: false,
         selectedBooking: false,
         showPdfModal: false,
         pdfToken: false,
      };
   }

   componentDidUpdate(prevProps, prevState, snapshot) {
      if (prevProps.mooring.mooring_id != this.props.mooring.mooring_id) {
         this.setState({
            bookingDetails: false,
         });
      }
   }

   onCalendarDateChange = (value) => {
      this.setState({ bookingDetails: false });
      if (moment(value).format('YYYY-MM-DD') == '2020-12-15') {
         this.setState({ range: true });
      }

      if (!this.props.mooring.own) {
         return;
      }

      //Dont update state if already selected
      if (
         moment(this.state.bookingDetails.from_date) <= value &&
         moment(this.state.bookingDetails.to_date) > value
      ) {
         if (this.state.selectedBooking) {
            return;
         }
      }

      value = moment(value);
      this.props.mooring.bookings.map((booking) => {
         const from_date = moment(booking.from_date);
         const to_date = moment(booking.to_date);
         if (from_date <= value && to_date > value) {
            getBookingDetails(
               booking.booking_id,
               (bookingDetails) => {
                  this.setState({
                     bookingDetails: bookingDetails,
                     selectedBooking: booking,
                  });
               },
               (err) => {},
            );
         } else {
            this.setState({ selectedBooking: false });
         }
      });
   };

   showBoatDocumentsPressed = () => {
      getBoatDocumentToken(
         this.state.bookingDetails.boat_id,
         (token) => {
            this.setState({ pdfToken: token, showPdfModal: true });
         },
         () => {},
      );
   };

   onClickDay = (e, available_days) => {
      this.props.onFromDateChange(moment(e));
      this.props.onToDateChange(moment(e).add(1, 'days'));
   };

   renderBookingDetails = () => {
      const { t } = this.props;
      const { bookingDetails, selectedBooking } = this.state;

      const extension_date =
         bookingDetails.booking && bookingDetails.booking.extension
            ? bookingDetails.booking.extension.to_date
            : null;
      const boat =
         selectedBooking && selectedBooking.boat ? selectedBooking.boat : false;
      const dateRangeText = extension_date
         ? `${moment(bookingDetails.from_date).format(DATE_FORMAT)} - ${moment(bookingDetails.to_date).format(DATE_FORMAT)} (${moment(extension_date).format(DATE_FORMAT)})`
         : `${moment(bookingDetails.from_date).format(DATE_FORMAT)} - ${moment(bookingDetails.to_date).format(DATE_FORMAT)}`;

      return (
         <div id='example-collapse-text'>
            <FormInput
               readOnly
               type='text'
               label={t('MooringDetails.from') + ' - ' + t('MooringDetails.to')}
               value={dateRangeText}
            />
            <FormInput
               readOnly
               type='text'
               label={t('MaintainTimesAndPrices.name')}
               value={`${bookingDetails.firstname} ${bookingDetails.lastname}`}
            />
            <FormInput
               readOnly
               type='text'
               label={t('MaintainTimesAndPrices.email')}
               value={bookingDetails.email}
            />
            <FormInput
               readOnly
               type='text'
               label={t('MaintainTimesAndPrices.mobile')}
               value={bookingDetails.mobilenumber}
            />
            {bookingDetails.notes && (
               <FormInput
                  as='textarea'
                  readOnly
                  type='text'
                  label={t('MooringDetails.notes')}
                  value={`${bookingDetails.notes}`}
                  rows={getTextAreaHeight(bookingDetails.notes)}
               />
            )}
            {boat && boat.registration_number != '' && (
               <FormInput
                  label={t(`MaintainTimesAndPrices.boatRegistrationNumber`)}
                  value={`${selectedBooking.boat.registration_number} `}
                  editable={false}
                  pointerEvents='none'
                  last={selectedBooking.boat.registration_number == ''}
               />
            )}
            {boat && boat.length && (
               <FormInput
                  label={t(`MaintainTimesAndPrices.boatDimensions`)}
                  value={`${selectedBooking.boat.length} / ${selectedBooking.boat.beam} / ${selectedBooking.boat.draft} m`}
                  editable={false}
                  pointerEvents='none'
                  last={true}
               />
            )}
            {boat && boat.name != '' && (
               <FormInput
                  label={t(`EditBoatModal.name`)}
                  value={`${selectedBooking.boat.name} `}
                  editable={false}
                  pointerEvents='none'
                  last={selectedBooking.boat.name == ''}
               />
            )}
            {boat && boat.make != '' && (
               <FormInput
                  label={t(`EditBoatModal.make`)}
                  value={`${selectedBooking.boat.make} `}
                  editable={false}
                  pointerEvents='none'
                  last={selectedBooking.boat.make == ''}
               />
            )}
            {boat && boat.type && selectedBooking.boat.type != '' && (
               <FormInput
                  label={t(`EditBoatModal.type`)}
                  value={`${selectedBooking.boat.type} `}
                  editable={false}
                  pointerEvents='none'
                  last={selectedBooking.boat.type == ''}
               />
            )}
            {boat && boat.drive && selectedBooking.boat.drive != '' && (
               <FormInput
                  label={t(`EditBoatModal.drive`)}
                  value={`${selectedBooking.boat.drive} `}
                  editable={false}
                  pointerEvents='none'
                  last={selectedBooking.boat.drive == ''}
               />
            )}{' '}
            {boat && boat.weight && (
               <FormInput
                  label={t(`EditBoatModal.weight`)}
                  value={`${selectedBooking.boat.weight} `}
                  editable={false}
                  pointerEvents='none'
                  last={selectedBooking.boat.weight == ''}
               />
            )}
            {bookingDetails.boat_id && bookingDetails.documents && (
               <Button onClick={this.showBoatDocumentsPressed}>
                  {t('MaintainTimesAndPrices.showBoatDocuments')}
               </Button>
            )}
            <CancelButton
               key={this.state.selectedBooking.booking_id}
               booking={this.state.selectedBooking}
               onCancelSuccess={() => {
                  this.setState({ selectedBooking: false });
                  this.props.reloadMooring();
               }}
            />
         </div>
      );
   };

   render() {
      const { mooring } = this.props;
      const { bookingDetails } = this.state;
      const bookingDetailsDropdown =
         mooring.own && this.state.selectedBooking && this.state.bookingDetails;

      var all_available_days = [];
      var all_available_day_by_day_dates = [];
      var all_booked_days = [];
      var selected_days = [];

      if (mooring.booking_times && mooring.bookings) {
         mooring.booking_times.map((booking_time) => {
            booking_time = { ...booking_time };
            const to_date_max = moment().add(18, 'months');
            if (moment(booking_time.to_date) > to_date_max) {
               booking_time.to_date = to_date_max.format('YYYY-MM-DD');
            }
            const available = getDaysBetweenPeriod(
               booking_time.from_date,
               booking_time.to_date,
            );
            if (booking_time.day_by_day) {
               all_available_day_by_day_dates.push(...available);
            } else {
               all_available_days.push(...available);
            }
         });

         mooring.bookings.map((booking_time) => {
            const booked = getDaysBetweenPeriod(
               booking_time.from_date,
               booking_time.to_date,
            );
            all_booked_days.push(...booked);
         });
      }
      selected_days.push(
         ...getDaysBetweenPeriod(this.state.startDate, this.state.endDate),
      );
      return (
         <div>
            {bookingDetails.boat_id && (
               <PdfModal
                  onClick={() => {}}
                  handleClose={() => {
                     this.setState({ showPdfModal: false });
                  }}
                  show={this.state.showPdfModal}
                  pdfUrl={`${process.env.REACT_APP_API_HOST}boatdocuments/get_my_pdf?token=${this.state.pdfToken}`}
               />
            )}
            <Calendar
               style={{ width: 'none' }}
               onChange={this.onCalendarDateChange}
               onClickDay={(e) => this.onClickDay(e, all_available_days)}
               onSelect={this.onSelect}
               selectRange={false}
               value={this.state.date}
               // defaultActiveStartDate={new Date("2021-01-20")}
               tileClassName={({ date, view }) => {
                  if (
                     selected_days.find(
                        (x) => x === moment(date).format('YYYY-MM-DD'),
                     )
                  ) {
                     return 'selected';
                  }
                  if (
                     all_booked_days.find(
                        (x) => x == moment(date).format('YYYY-MM-DD'),
                     )
                  ) {
                     return 'booked';
                  }
                  if (
                     all_available_day_by_day_dates.find(
                        (x) => x === moment(date).format('YYYY-MM-DD'),
                     )
                  ) {
                     return 'available_day_by_day';
                  }
                  if (
                     all_available_days.find(
                        (x) => x === moment(date).format('YYYY-MM-DD'),
                     )
                  ) {
                     return 'available';
                  }
               }}
            />

            <Collapse in={bookingDetailsDropdown}>
               <div id='example-collapse-text'>
                  {this.renderBookingDetails()}
               </div>
            </Collapse>
         </div>
      );
   }
}

export default withTranslation()(BookingCalendar);
