const COLORS = {
   MAIN_BLUE: '#003066',
   MAIN_WHITE: '#ffffff',
   SECONDARY_BLUE: '#007fff',
   MAIN_GRAY: '#818582',
   MAIN_RED: '#fe8787',
   MAIN_GREEN: '#75d701',
   SECONDARY_GRAY: '#1faadb',
   SECONDARY_RED: '#fe3824',
   IOS_BLUE: '#007aff',
   TERTIARY_GRAY: '#f3f3f7',
   LINE_GRAY: '#c8c7cc',
   LINE_BLUE: '#0076ff',
   WHITE_OPAQUE: 'rgba(255, 255, 255, 0.85)',
   BLUE_OPAQUE: 'rgba(0, 48, 102, 0.2)',
   GRAY_OPAQUE: 'rgba(129, 133, 140, 0.05)',
   GRAY_OUTLINE: 'rgba(0, 24, 51, 0.18)',
   TRANSPARENT: 'transparent',
   GREEN_MOORING_AVAILABLE: '#60c100',
   GREEN_MOORING_AVAILABLE_CALENDAR: 'green',
   RED_MOORING_UNAVAILABLE: 'red',
   RED_MOORING_INTERNAL_UNPAID: '#BB0000',
   ORANGE_MOORING_UNCONFIRMED: '#e67e00',
   BLUE_MOORING_PARTIALLY_AVAILABLE: '#007fff',
   GRAY_INACTIVE: 'rgba(0,0,0,0.15)',
   WHITE_ACTIVE: 'rgba(255,255,255,1)',
   ORANGE_PROVISIONAL: 'orange',
};
export default COLORS;
