import COLORS from '../constants/colors';

const globalStyles = {
   button: {
      backgroundColor: 'white',
      color: COLORS.MAIN_GRAY,
      fontWeight: 'bold',
      borderColor: COLORS.MAIN_GRAY,
      marginRight: 10,
      marginTop: 5,
      marginBottom: 5,
   },
   saveButton: {
      float: 'right' as const,
      backgroundColor: COLORS.MAIN_GREEN,
      fontWeight: 'bold',
      borderColor: COLORS.GREEN_MOORING_AVAILABLE,
   },
   redButton: {
      marginLeft: 10,
      float: 'right',
      backgroundColor: 'red',
      fontWeight: 'bold',
      borderColor: COLORS.GREEN_MOORING_AVAILABLE,
   },
   normalButton: {
      backgroundColor: 'white',
      color: COLORS.MAIN_GRAY,
      fontWeight: 'bold',
      borderColor: COLORS.MAIN_GRAY,
      height: 40,
      width: '100%',
      marginTop: 5,
      marginBottom: 5,
      borderRadius: 5,
   },
   saveButtonGreen: {
      backgroundColor: COLORS.MAIN_GREEN,
      color: 'white',
   },
   buttonOrange: {
      backgroundColor: 'orange',
      color: 'white',
   },
   deleteButton: {
      backgroundColor: 'white',
      color: 'red',
      fontWeight: 'bold',
      borderColor: COLORS.MAIN_GRAY,
   },
   colForm: {
      backgroundColor: 'white',
      padding: 20,
      height: '100%',
   },
   link: {
      color: COLORS.MAIN_BLUE,
      textDecorationLine: 'underline',
   },
   leftHalfScreenContainer: {
      height: '100%',
      overflow: 'scroll',
   },
   section: {
      backgroundColor: 'white',
      marginBottom: 20,
      padding: 20,
      borderRadius: 5,
   },
   transparent: {
      background: 'transparent',
      border: 'none',
   },
   noticeWarn: {
      backgroundColor: '#ffc7c7',
      borderColor: 'red',
      borderStyle: 'solid',
      borderRadius: 5,
      borderWidth: 2,
      padding: 10,
      marginTop: 10,
      marginBottom: 10,
   },
};

export default globalStyles;
