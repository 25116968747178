import React, {
   useCallback,
   useEffect,
   useMemo,
   useRef,
   useState,
} from 'react';
import { Col, Container, Modal, Row } from 'react-bootstrap';
import DataGrid, {
   type DataGridProps,
   Row as DataGridRow,
   type SortColumn,
} from 'react-data-grid';
import { useTranslation } from 'react-i18next';

import { getWaitingContacts } from '../actions/waiting_contacts';
import COLORS from '../constants/colors';
import type { WaitingContact } from '../types/Contact';
import { deepSearchString } from '../utils/utilities';

const i18nPrefix = 'ContactTable';

type ContactDataGridProps = DataGridProps<WaitingContact>;

type Props = {
   showModal: boolean;
   hideModal: () => void;
   onSelectContact: (contact: WaitingContact) => void;
};
const WaitingContactListModal = ({
   showModal,
   hideModal,
   onSelectContact,
}: Props) => {
   const { t: translate } = useTranslation();
   const [contacts, setContacts] = useState<WaitingContact[]>();
   const [sortedContacts, setSortedContacts] = useState<WaitingContact[]>([]);
   const [columnSortings, setColumnSortings] = useState<SortColumn[]>();
   const [searchText, setSearchText] = useState('');
   const searchInputRef = useRef();

   const extendReservations = useCallback(
      (waiting_contacts) => {
         const NoOrYes = translate('global.NoOrYes');
         waiting_contacts.forEach((waiting_contact) => {
            waiting_contact.contact_name = `${waiting_contact.contact.lastname} ${waiting_contact.contact.firstname}`;
            waiting_contact.contact_address = `${waiting_contact.contact.street} ${waiting_contact.contact.zip} ${waiting_contact.contact.city}`;
            waiting_contact.email = `${waiting_contact.contact.email}`;
            waiting_contact.contact_type = `${waiting_contact.contact.type}`;

            //Formating
            if (waiting_contact.mooring_type == null)
               waiting_contact.mooring_type = '';
            if (waiting_contact.mooring_zone == null)
               waiting_contact.mooring_zone = '';

            waiting_contact.relocation_string =
               NoOrYes[waiting_contact.relocation];
            waiting_contact.annual_mooring_interested_string =
               NoOrYes[waiting_contact.annual_mooring_interested];
            waiting_contact.archived_string = NoOrYes[waiting_contact.archived];
         });
         return waiting_contacts;
      },
      [translate],
   );

   useEffect(() => {
      getWaitingContacts(
         (waiting_contacts) => {
            const extendedReservations = extendReservations(waiting_contacts);
            setContacts(extendedReservations);
            setSortedContacts(extendedReservations);
         },
         () => {},
      );
   }, [extendReservations]);

   const getFilteredRows = (contacts: WaitingContact[], sText: string) => {
      let fContacts = [...contacts];
      if (sText) {
         fContacts = deepSearchString(fContacts, sText);
      }
      return fContacts;
   };

   const getSortedRows = (
      contacts: WaitingContact[],
      colSortings: SortColumn[],
   ) => {
      const sortedContacts = [...contacts];
      if (colSortings && colSortings[0]) {
         const columnKey = colSortings[0].columnKey;
         const direction = colSortings[0].direction;
         sortedContacts.sort(function (a, b) {
            const comparison = a[columnKey] > b[columnKey] ? 1 : -1;
            return direction === 'ASC' ? comparison : -comparison;
         });
      }
      return sortedContacts;
   };

   const onSortColumnsChange: ContactDataGridProps['onSortColumnsChange'] = (
      colSortings,
   ) => {
      console.log('OnSortColumnsChange', colSortings, searchText);

      setColumnSortings(colSortings);

      const sorted_waiting_contacts = getSortedRows(contacts, colSortings);
      const filtered_sorted_waiting_contacts = getFilteredRows(
         sorted_waiting_contacts,
         searchText,
      );
      setSortedContacts(filtered_sorted_waiting_contacts);
   };

   const onRowClick: ContactDataGridProps['onRowClick'] = (contact, arg2) => {
      console.log('onRowClick: ', contact, arg2);
   };

   const onRowDoubleClick: ContactDataGridProps['onRowDoubleClick'] = (
      contact,
      arg2,
   ) => {
      console.log('onRowDoubleClick: ', contact, arg2);
      onSelectContact(contact);
   };

   const rowRenderer: ContactDataGridProps['rowRenderer'] = (props) => {
      // const color = this.state.tableState[props.idx].CHECKED === 'Y' ? "blue" : "";

      let provisional_assigned_highlight = false;
      let illegal_assigned_highlight = false;
      if (props.row?.assigned_contacts.length != 0) {
         const assigned_contact = props.row?.assigned_contacts[0];
         if (assigned_contact.provisional) {
            provisional_assigned_highlight = true;
         } else {
            illegal_assigned_highlight = true;
         }
      }

      //   // }
      // }
      const className = provisional_assigned_highlight
         ? 'row-highlight-provisional'
         : illegal_assigned_highlight
           ? 'row-highlight-illegal'
           : '';

      return (
         <div className={className}>
            <DataGridRow {...props} />
         </div>
      );
   };

   const columns = useMemo(() => {
      return [
         {
            key: 'contact_name',
            name: translate(`${i18nPrefix}.contact_name`),
            width: 150,
            frozen: true,
         },
         {
            key: 'contact_address',
            name: translate(`${i18nPrefix}.contact_address`),
            width: 150,
            frozen: true,
         },
         {
            key: 'contact_type',
            name: translate(`${i18nPrefix}.contact_type`),
            width: 150,
            frozen: true,
         },
         {
            key: 'entry_date',
            name: translate(`${i18nPrefix}.entry_date`),
            width: 130,

            frozen: true,
         },
         {
            key: 'email',
            name: translate(`${i18nPrefix}.email`),
            width: 130,
         },
         {
            key: 'mooring_zone',
            name: translate(`${i18nPrefix}.mooring_zone`),
            width: 100,
         },
         {
            key: 'mooring_type',
            name: translate(`${i18nPrefix}.mooring_type`),
            width: 100,
         },
         {
            key: 'boat_type',
            name: translate(`${i18nPrefix}.boat_type`),
            width: 100,
         },
         {
            key: 'min_length',
            name: translate(`${i18nPrefix}.min_length`),
            width: 100,
         },
         {
            key: 'min_beam',
            name: translate(`${i18nPrefix}.min_beam`),
            width: 100,
         },
         {
            key: 'min_draught',
            name: translate(`${i18nPrefix}.min_draught`),
            width: 100,
         },
         {
            key: 'relocation_string',
            name: translate(`${i18nPrefix}.relocation`),
            width: 100,
         },
         {
            key: 'annual_mooring_interested_string',
            name: translate(`${i18nPrefix}.annual_mooring_interested`),
            width: 150,
         },
         {
            key: 'comment',
            name: translate(`${i18nPrefix}.comment`),
            width: 150,
         },
         {
            key: 'last_examination',
            name: translate(`${i18nPrefix}.last_examination`),
            width: 150,
         },
         {
            key: 'archived_string',
            name: translate(`${i18nPrefix}.archived`),
            width: 150,
         },
         {
            key: 'archiving_reason',
            name: translate(`${i18nPrefix}.archiving_reason`),
            width: 150,
         },
         {
            key: 'archiving_date',
            name: translate(`${i18nPrefix}.archiving_date`),
            width: 150,
         },
         {
            key: 'registration_fee',
            name: translate(`${i18nPrefix}.registration_fee`),
            width: 150,
         },
         {
            key: 'periodic_fee',
            name: translate(`${i18nPrefix}.periodic_fee`),
            width: 150,
         },
         {
            key: 'currency',
            name: translate(`${i18nPrefix}.currency`),
            width: 150,
         },
      ];
   }, [translate]);

   return (
      <Modal animation={false} size='xl' show={showModal} onHide={hideModal}>
         <Modal.Header closeButton>
            <Modal.Title>
               {translate(`${i18nPrefix}.selectContact`)}
            </Modal.Title>
         </Modal.Header>
         <Modal.Body>
            <Container style={{ maxHeight: 'none', backgroundColor: 'white' }}>
               <Row>
                  <Col sm={4}>
                     <h1>{'Warteliste'}</h1>
                     <h2>{'Auswählen '}</h2>
                  </Col>
               </Row>

               <Row>
                  <Col md={8}>
                     <input
                        style={{
                           marginTop: 0,
                           outline: 'none',
                           marginBottom: 10,
                           width: '100%',
                           fontSize: 20,
                           border: 'none',
                           borderRadius: 0,
                           paddingLeft: 0,
                           backgroundColor: 'white',
                           color: COLORS.MAIN_BLUE,
                           boxShadow: 'none',
                        }}
                        autoFocus={true}
                        ref={searchInputRef}
                        placeholder={translate(`global.search`)}
                        value={searchText}
                        onChange={(e) => {
                           setSearchText(e.target.value);
                        }}
                     />
                     <div
                        style={{
                           width: '100%',
                           height: 1,
                           backgroundColor: COLORS.GRAY_OUTLINE,
                        }}></div>
                  </Col>
               </Row>

               <div style={{ marginTop: 15 }}>
                  <DataGrid
                     style={{ maxHeight: 'none', height: 700 }}
                     columns={columns}
                     rows={sortedContacts}
                     className='rdg-light'
                     rowKeyGetter={(contact) => contact.id}
                     defaultColumnOptions={{
                        sortable: true,
                        resizable: true,
                     }}
                     rowRenderer={rowRenderer}
                     onSortColumnsChange={onSortColumnsChange}
                     sortColumns={columnSortings}
                     onRowClick={onRowClick}
                     onRowDoubleClick={onRowDoubleClick}
                  />
               </div>
            </Container>
         </Modal.Body>
      </Modal>
   );
};

export default WaitingContactListModal;
