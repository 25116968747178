import React from 'react';
import {
   Alert,
   Button,
   Col,
   Container,
   FormControl,
   Row,
   Spinner,
} from 'react-bootstrap';
import { withTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { cancelBooking } from '../actions/bookings';
import p_active from '../assets/maintain_bookings_screen/p_active.png';
import p_inactive from '../assets/maintain_bookings_screen/p_inactive.png';
import COLORS from '../constants/colors';
import globalStyles from '../stylesheets/globalStyles';

class CancelButton extends React.Component {
   constructor(props) {
      super(props);

      this.state = {
         loading: false,
         success: false,
         error: false,
      };
   }

   handleCancelClick = () => {
      window.confirm(
         this.props.t(`${'BookingItem'}.areYouSureCancelBooking`),
      ) &&
         this.setState({ loading: true }, () => {
            cancelBooking(
               this.props.booking,
               this.cancelBookingSuccess,
               this.cancelBookingFail,
            );
         });
   };

   cancelBookingSuccess = () => {
      this.setState({ loading: false }, () => {
         if (typeof this.props.onCancelSuccess === 'function') {
            this.props.onCancelSuccess();
         }
      });
   };

   cancelBookingFail = () => {
      this.setState({ loading: false });
   };

   render() {
      const { t } = this.props;
      const { booking } = this.props;
      return (
         <div>
            {booking.total_price == 0 && (
               <Button
                  id={this.props?.id}
                  style={{ ...globalStyles.normalButton, color: 'red' }}
                  onClick={this.handleCancelClick}>
                  {t('MooringDetails.cancel')}

                  {this.state.loading && (
                     <Spinner
                        style={{ width: 20, height: 20 }}
                        animation='border'
                        variant='secondary'
                        role='status'
                     />
                  )}
               </Button>
            )}
         </div>
      );
   }
}

const styles = {
   button: {
      width: '100%',
      backgroundColor: 'white',
      color: COLORS.MAIN_GRAY,
      fontWeight: 'bold',
      borderColor: COLORS.MAIN_GRAY,
      marginRight: 10,
   },
};

export default withTranslation()(CancelButton);
