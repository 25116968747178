import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import { Modal, Spinner } from 'react-bootstrap';
import { useDropzone } from 'react-dropzone';
import { useTranslation } from 'react-i18next';

import { uploadDocument } from '../actions/documents';
import globalStyles from '../stylesheets/globalStyles';
import FormInput from './FormInput';
import FormValueContainer from './FormValueContainer';

const i18nPrefix = 'MaintainDocuments';

type Props = {
   onRef: React.MutableRefObject<
      | {
           show: (boat_document: any) => void;
        }
      | undefined
   >;
   onClose?: () => void;
   id_params: any;
};

const AddDocumentModal = (props: Props) => {
   const { t } = useTranslation();

   const [showModal, setShowModal] = useState(false);
   const [loading, setLoading] = useState(false);
   const [selectedFile, setSelectedFile] = useState(null);
   const [selectedFileName, setSelectedFileName] = useState(null);
   const [title, setTitle] = useState('');
   const [description, setDescription] = useState('');

   const { getRootProps, getInputProps } = useDropzone({
      accept: {
         image: ['png', 'jpeg'],
         application: ['pdf'],
      },
      onDrop: (acceptedFiles) => {
         console.log('acceptedFiles dragndrop');
         handleDropInput(acceptedFiles);
      },
   });

   useEffect(
      () => {
         if (props.onRef) {
            props.onRef.current = { show };
         }
         return () => {
            if (props.onRef) {
               props.onRef.current = undefined;
            }
         };
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [],
   );

   const show = (boat_document) => {
      setShowModal(true);
   };

   const handleClose = () => {
      props.onClose && props.onClose();
      setShowModal(false);
   };

   const handleDropInput = (files) => {
      const file = files[0];
      const error = false;

      if (
         file.type != 'image/png' &&
         file.type != 'image/jpg' &&
         file.type != 'image/jpeg' &&
         file.type != 'application/pdf'
      ) {
         console.log('Wrong file');
         console.log('filetype', file.type);
         //
         window.confirm(t(`${i18nPrefix}.invalidFileType`));
         return;
      }
      if (!error) {
         setSelectedFile(file);
         if (title == '') setTitle(file.name);
      }
   };

   const handleFileInput = (event) => {
      const file = event.target.files[0];
      const error = false;

      if (
         file.type != 'image/png' &&
         file.type != 'image/jpg' &&
         file.type != 'image/jpeg' &&
         file.type != 'application/pdf'
      ) {
         console.log('Wrong file');
         console.log('filetype', file.type);
         //
         window.confirm(t(`${i18nPrefix}.invalidFileType`));
         return;
      }
      if (!error) {
         setSelectedFile(file);
         setSelectedFileName(event.target.value);
         if (title == '') setTitle(file.name);
      }
   };

   const uploadPressed = (event) => {
      const formData = new FormData();
      if (!selectedFile) {
         return;
      }
      formData.append('photo', selectedFile, selectedFile.name);

      formData.append('title', title);
      formData.append('description', description);

      // append all params, for example {mooring_id: 1}
      for (const param in props.id_params) {
         formData.append(param, props.id_params[param]);
      }

      setLoading(true);
      uploadDocument(
         formData,
         () => {
            // success
         },
         () => {
            // error
         },
         () => {
            // final
            setInitialState();

            handleClose();
         },
      );
   };

   const setInitialState = () => {
      setLoading(false);
      setSelectedFile(null);
      setSelectedFileName(null);
      setTitle('');
      setDescription('');
   };

   return (
      <Modal size='lg' show={showModal} onHide={handleClose} animation={true}>
         <Modal.Header closeButton>
            <Modal.Title> {t(`${i18nPrefix}.addDocument`)} </Modal.Title>
         </Modal.Header>
         <Modal.Body>
            <div style={{ marginBottom: 5 }}>
               <FormInput
                  type='text'
                  label={t(`${i18nPrefix}.title`)}
                  value={title}
                  onChange={(e) => setTitle(e.target.value)}
               />
               <FormInput
                  as='textarea'
                  type='description'
                  label={t(`${i18nPrefix}.description`)}
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
               />

               <FormValueContainer last label={t(`${i18nPrefix}.file`)}>
                  <div
                     style={{
                        marginTop: 10,
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        height: '300px',
                        border: '2px dashed #000',
                        borderRadius: '10px',
                     }}
                     {...getRootProps()}>
                     <input {...getInputProps()} />
                     <p>
                        {selectedFile?.path
                           ? selectedFile.path
                           : t(`${i18nPrefix}.dragAndDrop`)}
                     </p>
                  </div>
               </FormValueContainer>

               <button
                  style={globalStyles.normalButton}
                  onClick={uploadPressed}
                  disabled={loading}>
                  {t(`${i18nPrefix}.upload`)}
                  {loading && (
                     <Spinner
                        style={{ width: 20, height: 20, marginLeft: 10 }}
                        animation='border'
                        role='status'
                     />
                  )}
               </button>
            </div>
         </Modal.Body>
      </Modal>
   );
};

AddDocumentModal.propTypes = {
   onRef: PropTypes.object.isRequired, // reference to this component
   onClose: PropTypes.func, // function to call when modal is closed
   id_params: PropTypes.object.isRequired, // params for load_documenty_by: example {mooring_id: 1}
};

export default AddDocumentModal;
