import PropTypes from 'prop-types';
import React from 'react';
import {
   Alert,
   Button,
   Col,
   Container,
   FormControl,
   Row,
} from 'react-bootstrap';
import { withTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { addBooking } from '../actions/bookings';
import p_active from '../assets/maintain_bookings_screen/p_active.png';
import p_inactive from '../assets/maintain_bookings_screen/p_inactive.png';
import COLORS from '../constants/colors';

class StatusAlerts extends React.Component {
   constructor(props) {
      super(props);
   }
   render() {
      const { t, state, errorMessage, loadingMessage } = this.props;

      const successMessage = this.props.successMessage
         ? this.props.successMessage
         : state.successMessage
           ? state.successMessage
           : t('MaintainTimesAndPrices.updateSuccessful');
      return (
         <div style={{ marginTop: 10 }}>
            {state.loading && (
               <Alert key={1} variant={'primary'}>
                  {loadingMessage || t('global.loading')}
               </Alert>
            )}
            {state.success && (
               <Alert id='alert_success' key={1} variant={'success'}>
                  {successMessage}
               </Alert>
            )}
            {state.error && (
               <Alert id='alert_error' key={1} variant={'danger'}>
                  {errorMessage || t('errors.somethingWentWrong')}
               </Alert>
            )}
         </div>
      );
   }
}

const styles = {
   button: {
      backgroundColor: 'white',
      color: COLORS.MAIN_GRAY,
      fontWeight: 'bold',
      borderColor: COLORS.MAIN_GRAY,
      marginRight: 10,
      marginTop: 5,
      marginBottom: 5,
   },
};

export default withTranslation()(StatusAlerts);
