import '../stylesheets/calendar.css';

import _ from 'lodash';
import moment from 'moment';
import React from 'react';
import {
   Alert,
   Button,
   Col,
   Container,
   Form,
   Image,
   Modal,
   Row,
} from 'react-bootstrap';
import DatePicker from 'react-date-picker'; // do not remoove...
import { withTranslation } from 'react-i18next';

import { updateBookingTimes } from '../actions/bookingtimes';
import {
   deleteMooring,
   getMyMooring,
   updateFinances,
} from '../actions/moorings';
import QRIcon from '../assets/icons/bp_qr_symbol.png';
import AddNewButton from '../components/AddNewButton';
import BookingCalendar from '../components/BookingCalendar';
import BookingTimeItem from '../components/BookingTimeItem';
import FormInput from '../components/FormInput';
import FormSegmentedControl from '../components/FormSegmentedControl';
import StatusAlerts from '../components/StatusAlerts';
import { CURRENCIES } from '../constants/constants';
import EditMooring from '../halfscreens/EditMooring';
import i18n from '../i18n';
import globalStyles from '../stylesheets/globalStyles';
import { validateMaintainTimesAndPricesForm } from '../utils/validators';

// import { SegmentedControl } from 'segmented-control'
class MaintainTimesAndPrices extends React.Component {
   constructor(props) {
      super(props);
      this.state = {
         loading: false,
         success: false,
         error: false,
         role: 'marina',
         communicationLanguage: 'en',
         harbor_fee_included: true,
         tourist_tax_adult: 0,
         tourist_tax_child: 0,
         mooring_id: null,
         mooring: {
            harbour_name: '',
            berth_number: '',
            harbor_fee_included: '',
            payment_currency: '',
         },
         booking_times: [],
         original_booking_times: [],
         selected: 1,
      };
   }

   componentDidMount() {
      // if (this.props.location.state.mooring) {
      //     console.log(this.props.match.params);
      //     console.log("Mooring from lastscreen", this.props.location.state.mooring);
      //     this.setState({ mooring: this.props.location.state.mooring });

      // }
      // else
      if (this.props.match.params) {
         const mooring_id = this.props.match.params.id;
         this.setState({ mooring_id: mooring_id });
         this.loadBookingTimes(mooring_id);
      }
   }

   loadBookingTimes = (mooring_id = this.state.mooring_id) => {
      getMyMooring(mooring_id, this.getMyMooringSuccess, this.getMyMooringFail);
   };

   getMyMooringSuccess = (mooring) => {
      //only show active booking_times
      const booking_times = mooring.booking_times.filter(
         (booking_time) =>
            moment(booking_time.to_date) >= moment().subtract(1, 'day'),
      );

      this.setState({
         mooring: mooring,
         booking_times: booking_times,
         original_booking_times: booking_times,
      });
      this.forceUpdate();
   };

   getMyMooringFail = (err) => {};

   handleBookingTimeChange = (index, updatedBookingTime) => {
      this.setState({ bookingtimes_changed: true });

      const booking_times = this.state.booking_times;
      updatedBookingTime.mooring_id = this.state.mooring.mooring_id;
      updatedBookingTime.booking_time_id = booking_times[index].booking_time_id;
      booking_times[index] = updatedBookingTime;

      this.setState({
         booking_times: booking_times,
         initialPrice: updatedBookingTime.daily_rate,
      });
   };

   addNewBookingTime = () => {
      //optional value bookingtime from_date and to_date

      this.setState({ bookingtimes_changed: true });

      //Get initial values
      const booking_times = this.state.booking_times;
      const lastBookingTime = booking_times[booking_times.length - 1];
      const initial_from_date = lastBookingTime
         ? lastBookingTime.to_date
         : moment().format('YYYY-MM-DD');
      const initial_to_date = moment(initial_from_date)
         .add(1, 'days')
         .format('YYYY-MM-DD');
      const initial_price = this.state.mooring.daily_rate_default;

      const empty_booking_time = {
         from_date: initial_from_date,
         to_date: initial_to_date,
         day_by_day: false, //this.state.dayByDayDefault,
         daily_rate: initial_price, //this.state.initialPrice,
         mooring_id: this.state.mooring.mooring_id,
      };
      this.state.booking_times.push(empty_booking_time);
      // this.forceUpdate();
   };

   removeBookingTime(index) {
      this.setState({ bookingtimes_changed: true });
      const { booking_times } = this.state;
      _.pullAt(booking_times, [index]);
      this.setState({ booking_times: booking_times });
   }

   handleUpdateTimesClick = () => {
      const formErrors = validateMaintainTimesAndPricesForm(this.state);
      const noErrors = Object.values(formErrors).every((x) => x == false);
      if (!noErrors) {
         this.setState({
            error: this.props.t('errors.someValuesAreNotCorrect'),
         });
         return;
      }

      this.props.history.goBack();
      this.setState(
         { loading: true, success: false, error: false },
         () => {
            updateBookingTimes(
               this.state.booking_times,
               this.state.mooring.mooring_id,
               this.updateBookingTimesSuccess,
               this.updateBookingTimesFail,
            );
            updateFinances(
               this.state.mooring,
               () => {},
               (error) => {},
            );
         },
         () => {},
      );
   };

   updateBookingTimesSuccess = () => {
      this.setState({ loading: false, success: true, error: false }, () => {
         this.loadBookingTimes();
      });
   };

   updateBookingTimesFail = (err) => {
      this.setState({
         loading: false,
         success: false,
         error: err.data.message,
      });
   };

   render() {
      const { t } = this.props;
      const { mooring, booking_times } = this.state;
      const formErrors = validateMaintainTimesAndPricesForm(this.state);
      const current_language = i18n.language;
      const mooringLink = encodeURIComponent(`bpms.ch/${mooring.mooring_id}`);
      const QRLink = `https://api.qrserver.com/v1/create-qr-code/?size=512x512&data=${mooringLink}`;
      console.log('QRLink', QRLink);
      const NoOrYes = t('global.NoOrYes');
      return (
         <Container sm={12}>
            <Row>
               <Col sm={10}>
                  <h1>{t('MaintainTimesAndPrices.title')}</h1>

                  <h2>{`${mooring.harbour_name} ${mooring.berth_number}`}</h2>
               </Col>
               <Col sm={2}>
                  <Button
                     style={globalStyles.saveButton}
                     disabled={this.state.loading}
                     onClick={() => this.handleUpdateTimesClick()}>
                     {t('global.save')}
                  </Button>
               </Col>
            </Row>
            <Row>
               <Col md={12}>
                  <StatusAlerts state={this.state} />
               </Col>
            </Row>
            <Row style={{ marginBottom: 20 }}>
               <Col sm={6}>
                  <div style={globalStyles.colForm}>
                     <Row>
                        <Col md={10} sm={10}>
                           <h3>{t('MaintainTimesAndPrices.calendar')}</h3>
                        </Col>
                        <Col md={2} sm={2}>
                           <a href={QRLink} target='_blank' rel='noreferrer'>
                              <img
                                 style={{ width: 36, float: 'right' }}
                                 onClick={this.onClickWaitingContactsTable}
                                 src={QRIcon}
                                 alt='tableicon'
                              />
                           </a>
                        </Col>
                     </Row>

                     <BookingCalendar
                        style={{ width: '100%' }}
                        onChange={() => {}}
                        mooring={this.state.mooring}
                        onFromDateChange={() => {}}
                        onToDateChange={() => {}}
                     />
                  </div>
               </Col>

               <Col sm={6}>
                  <div style={globalStyles.colForm}>
                     <Row>
                        <Col sm={10}>
                           <h3>{t('MaintainTimesAndPrices.prices')}</h3>
                        </Col>
                     </Row>

                     <FormSegmentedControl
                        label={t('MaintainTimesAndPrices.feeIncluded')}
                        segments={NoOrYes}
                        selected={mooring.harbor_fee_included}
                        onChange={(index) =>
                           this.setState({
                              mooring: {
                                 ...this.state.mooring,
                                 harbor_fee_included: index,
                              },
                           })
                        }
                     />
                     <FormSegmentedControl
                        label={t('MaintainTimesAndPrices.currency')}
                        segments={CURRENCIES}
                        onChange={(index) =>
                           this.setState({
                              mooring: {
                                 ...this.state.mooring,
                                 payment_currency: CURRENCIES[index],
                              },
                           })
                        }
                        selected={CURRENCIES.indexOf(
                           this.state.mooring.payment_currency,
                        )}
                     />

                     {/* CURRENCIES.indexOf(this.state.payment_currency) */}

                     <FormInput
                        label={t('MaintainTimesAndPrices.defaultPrice')}
                        type={mooring.custom_price ? 'text' : 'number'}
                        value={
                           mooring.custom_price
                              ? 'custom'
                              : mooring.daily_rate_default
                        }
                        onChange={(text) => {
                           this.setState({
                              mooring: {
                                 ...this.state.mooring,
                                 daily_rate_default: text.target.value,
                              },
                           });
                        }}
                        disabled={mooring.custom_price}
                     />

                     <FormInput
                        label={t('MaintainTimesAndPrices.tourist_tax_adult')}
                        type='number'
                        value={this.state.mooring.tourist_tax_adult}
                        onChange={(text) => {
                           this.setState({
                              mooring: {
                                 ...this.state.mooring,
                                 tourist_tax_adult: text.target.value,
                              },
                           });
                        }}
                     />

                     <FormInput
                        label={t('MaintainTimesAndPrices.tourist_tax_child')}
                        type='number'
                        value={this.state.mooring.tourist_tax_child}
                        onChange={(text) => {
                           this.setState({
                              mooring: {
                                 ...this.state.mooring,
                                 tourist_tax_child: text.target.value,
                              },
                           });
                        }}
                     />
                  </div>
               </Col>
            </Row>

            <Row>
               <Col sm={12}>
                  <div style={{ ...globalStyles.colForm, overflowX: 'hidden' }}>
                     <h3>{t('MaintainTimesAndPrices.availabilityPeriods')}</h3>
                     {formErrors.main && (
                        <div style={{ color: 'red' }}>
                           {t(`validators.${formErrors.main}`)}
                        </div>
                     )}

                     <div style={{ boxSizing: 'border-box' }}>
                        {booking_times.map((booking_time, index) => (
                           <BookingTimeItem
                              key={booking_time.booking_time_id}
                              mooring={mooring}
                              booking_time={booking_time}
                              change={(updatedBookingTime) =>
                                 this.handleBookingTimeChange(
                                    index,
                                    updatedBookingTime,
                                 )
                              }
                              remove={() => this.removeBookingTime(index)}
                              random={Math.random()}
                           />
                        ))}

                        <AddNewButton
                           center
                           label={t('MaintainTimesAndPrices.addNewPeriod')}
                           onClick={this.addNewBookingTime}
                        />
                     </div>
                  </div>
               </Col>
            </Row>
         </Container>
      );
   }
}

export default withTranslation()(MaintainTimesAndPrices);
