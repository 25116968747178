import countries from 'countries-list';
import React from 'react';
import Select from 'react-select';

import COLORS from '../constants/colors';
import FormInput from './FormInput';

// const coutryOptions = countries.countries.map((country) => `${country.name}`);
var coutryOptions = [];
for (var code in countries.countries) {
   const country = countries.countries[code];
   const value = code;
   const label = `${country.emoji} ${country.native} (${code})`;
   coutryOptions.push({ value: value, label: label });
}

export default function CountrySelector(props) {
   //Props:
   // - value(string) 2-ISO country code
   // -onChange={(value) => {}} (function): value is again the ISO code

   var coutryOptionsIndex = coutryOptions
      .map(function (e) {
         return e.value;
      })
      .indexOf(props.value);
   var value = coutryOptions[coutryOptionsIndex];
   const label = props.label || '';
   const last = !!props.last;
   const { error } = props;
   var errorMessage = '';

   return (
      <div>
         {/* <div>
                <Select
                    options={coutryOptions}
                    value={value}
                    onChange={(value) => { props.onChange(value.value) }}
                />
            </div> */}

         <div style={{ marginBottom: 5 }}>
            <div
               style={{
                  color: COLORS.MAIN_GRAY,
                  alignSelf: 'center',
                  fontSize: 14,
                  float: 'left',
                  width: '100%',
               }}>
               {label.toUpperCase()}
               {props.unit ? ` [${props.unit}]` : ''}
            </div>
            {props.type == 'file' && (
               <div
                  style={{
                     overflow: 'hidden',
                     height: 15,
                     width: '100%',
                  }}></div>
            )}
            {error && (
               <div
                  style={{
                     float: 'right',
                     marginRight: 10,
                     color: COLORS.MAIN_RED,
                     alignSelf: 'center',
                     fontSize: 14,
                     left: 3,
                  }}>
                  {errorMessage}
               </div>
            )}
            <div
               style={{
                  border: 'none',
                  borderRadius: 0,
                  paddingLeft: 0,
                  backgroundColor: 'white',
                  color: COLORS.MAIN_BLUE,
                  marginBottom: 10,
               }}>
               <Select
                  options={coutryOptions}
                  value={value}
                  onChange={(value) => {
                     props.onChange(value.value);
                  }}
               />
            </div>
            {props.type == 'file' && (
               <div
                  style={{
                     overflow: 'hidden',
                     height: 5,
                     width: '100%',
                  }}></div>
            )}
            {!last && (
               <div
                  style={{
                     width: '100%',
                     height: 1,
                     backgroundColor: COLORS.GRAY_OUTLINE,
                  }}></div>
            )}
         </div>

         {/* <FormInput as="select"
                value={value}
                onChange={(value) => { props.onChange(value.value) }}

                error={props.error}
                label={props.label}
            >
                {coutryOptions.map((option) =>
                    < option value={option.value}>{option.label}</option>
                )
                }

            </FormInput> */}
      </div>
   );
}
