import '../i18n';

import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Button, Col, Container, Modal, Row } from 'react-bootstrap';
import { withTranslation } from 'react-i18next';

import {
   createAssignedContact,
   deleteAssignedContact,
   get_permanent_contact_by_mooring_id,
   updateAssignedContact,
} from '../actions/assigned_contacts';
import { getMyMooring } from '../actions/moorings';
import AddNewButton from '../components/AddNewButton';
import EditAssignedContactModal from '../components/EditAssignedContactModal';
import EditContactModal from '../components/EditContactModal';
import FormInput from '../components/FormInput';
import COLORS from '../constants/colors';
import globalStyles from '../stylesheets/globalStyles';
import { validateRegisterform } from '../utils/validators';

const i18nPrefix = 'MaintainAssignedContacts';

const styles = {
   AssignedContactActive: {
      marginBottom: 15,
      borderRadius: 5,
      padding: 10,
      borderColor: COLORS.MAIN_GRAY,
      borderStyle: 'solid',
      backgroundColor: COLORS.WHITE_ACTIVE,
   },
   AssignedContactPast: {
      marginBottom: 15,
      borderRadius: 5,
      padding: 10,
      borderColor: COLORS.MAIN_GRAY,
      borderStyle: 'solid',
      backgroundColor: COLORS.GRAY_INACTIVE,
   },
   AssignedContactProvisional: {
      marginBottom: 15,
      borderRadius: 5,
      padding: 10,
      borderColor: COLORS.MAIN_GRAY,
      borderStyle: 'solid,',
      backgroundColor: COLORS.ORANGE_PROVISIONAL,
   },
};

class MaintainAssignedContacts extends React.Component {
   constructor(props) {
      super(props);
      this.state = {
         loading: false,
         success: false,
         error: false,
         assigned_contacts: [],
         mooring_id: null,
         mooring: {},
         searchText: false,
      };

      this.EditAssignedContactModal = React.createRef();
      this.EditContactModal = React.createRef();
   }

   componentDidMount() {
      // Load params from url
      if (this.props.match.params.id) {
         const mooring_id = this.props.match.params.id;
         this.setState({
            mooring_id: mooring_id,
            assigned_contact: {
               ...this.state.assigned_contact,
               mooring_id: mooring_id,
            },
         });
         this.refreshAssignedContacts(mooring_id);
         getMyMooring(
            mooring_id,
            (mooring) => {
               this.setState({ mooring: mooring });
            },
            () => {},
         );
      }

      // @SAHIN => state should be ovwewritten
      /*if (this.props.location.state) {
         this.setState(this.props.location.state);
      }

      // Load params router
      if (this.props.location.state && this.props.location.state.contact) {
         const contact_id = this.props.location.state.contact.id;

         var waiting_contact_id = null;
         if (this.props.location.state.waiting_contact) {
            waiting_contact_id = this.props.location.state.waiting_contact.id;
         }

         this.setState(
            {
               assigned_contact: {
                  contact_id: contact_id,
                  waiting_contact_id: waiting_contact_id,
               },
            },
            () => {
               this.EditAssignedContactModal.current.show({
                  contact_id: contact_id,
                  waiting_contact_id: waiting_contact_id,
               });
            },
         );
      }*/
   }

   refreshAssignedContacts = (mooring_id = this.state.mooring_id) => {
      var now = moment();
      get_permanent_contact_by_mooring_id(
         mooring_id,
         (assigned_contacts) => {
            // Get active state of assigned_contact
            var general_exemption = false;
            assigned_contacts.forEach((assigned_contact) => {
               if (
                  moment(assigned_contact.from_date) <= now &&
                  moment(assigned_contact.to_date) >= now
               ) {
                  //Active now
                  if (assigned_contact.exemption == 1) {
                     general_exemption = true;
                  }
               }
            });

            assigned_contacts.forEach((assigned_contact) => {
               if (
                  moment(assigned_contact.from_date) <= now &&
                  moment(assigned_contact.to_date) >= now
               ) {
                  //Active now
                  if (general_exemption) {
                     //mooring is exempted
                     if (assigned_contact.exemption == 1) {
                        assigned_contact.active = true;
                     } else {
                        assigned_contact.active = false;
                     }
                  } else {
                     //no exemption
                     assigned_contact.active = true;
                  }
               } else {
                  // time period not active
                  assigned_contact.active = false;
               }
            });

            this.setState({ assigned_contacts: assigned_contacts });
         },
         (err) => {},
      );
      this.setState({ assigned_contact: {} });
   };

   editAssignedContactClicked = (assigned_contact) => {
      this.EditAssignedContactModal.current.show(assigned_contact);
   };

   addAssignedContactClicked = () => {
      this.EditAssignedContactModal.current.show();
   };
   handleShow = () => {
      this.setState({ show: true });
   };

   handleClose = () => {
      this.setState({ show: false, assigned_contact: {}, edit: false });
   };

   onContactClicked = (contact) => {
      this.EditContactModal.show(contact);
   };

   render() {
      const { t } = this.props;
      const { assigned_contacts, assigned_contact } = this.state;

      const role = localStorage.getItem('role');
      const management = role == 'management';

      return (
         <Container style={{ maxWidth: 1000, marginTop: 50, borderRadius: 0 }}>
            <EditAssignedContactModal
               mooring_id={this.state.mooring_id}
               onRef={this.EditAssignedContactModal}
               onClose={() => {
                  this.refreshAssignedContacts();
               }}
            />

            <EditContactModal
               onRef={(ref) => (this.EditContactModal = ref)}
               onClose={() => {}}
               onSave={() => {
                  this.refreshAssignedContacts();
               }}
            />

            <h1>{t(`${i18nPrefix}.title`)}</h1>

            <div
               style={{
                  backgroundColor: 'white',
                  borderRadius: 5,
                  paddingLeft: 10,
                  marginBottom: 10,
               }}>
               {this.state.mooring && this.state.mooring.harbour_name && (
                  <Row>
                     <Col>
                        <FormInput
                           label='Platznummer'
                           value={`${this.state.mooring.harbour_name} ${this.state.mooring.berth_number}`}
                           disabled
                           last
                        />
                     </Col>
                     <Col>
                        <FormInput
                           label='Platzzone'
                           value={this.state.mooring.mooring_zone}
                           disabled
                           last
                        />
                     </Col>
                     <Col>
                        <FormInput
                           label='Platztyp'
                           value={this.state.mooring.mooring_type}
                           disabled
                           last
                        />
                     </Col>
                     <Col>
                        <FormInput
                           label='Dimensionen (L/B/T)'
                           unit='m'
                           value={`${this.state.mooring.max_length}/${this.state.mooring.max_beam}/${this.state.mooring.max_draught}`}
                           disabled
                           last
                        />
                     </Col>
                  </Row>
               )}
            </div>

            <div style={{ paddingLeft: 10 }}>
               <Row>
                  <Col>
                     <div style={{ fontSize: 20, fontWeight: 'bold' }}>
                        Dauermieter
                     </div>
                  </Col>
                  <Col>
                     <div style={{ fontSize: 20 }}>Von</div>
                  </Col>
                  <Col>
                     <div style={{ fontSize: 20 }}>Bis</div>
                  </Col>

                  <Col>
                     <div style={{ fontSize: 20 }}>Freigestellt</div>
                  </Col>
                  <Col>
                     <div style={{ fontSize: 20 }}>Korrespondenz</div>
                  </Col>

                  {management && (
                     <Col>
                        <div style={{ fontSize: 20 }}>Bearbeiten</div>
                     </Col>
                  )}
               </Row>
            </div>

            {assigned_contacts
               .sort((a, b) => (a.to_date < b.to_date ? 1 : -1))
               .map((assigned_contact) => {
                  var assigned_contact_name = `${assigned_contact.contact.firstname} ${assigned_contact.contact.lastname}`;
                  if (assigned_contact.provisional == 1)
                     assigned_contact_name = `(${assigned_contact_name})`;

                  return (
                     <div
                        key={assigned_contact.id}
                        style={
                           assigned_contact.provisional != 1
                              ? assigned_contact.active == 1
                                 ? styles.AssignedContactActive
                                 : styles.AssignedContactPast
                              : styles.AssignedContactProvisional
                        }>
                        <Row>
                           <Col>
                              <div
                                 onClick={() =>
                                    this.onContactClicked(
                                       assigned_contact.contact,
                                    )
                                 }
                                 style={{ fontSize: 20, fontWeight: 'bold' }}
                                 className='pointer_on_hover'>
                                 {assigned_contact_name}
                              </div>
                           </Col>
                           <Col>
                              <div style={{ fontSize: 20 }}>
                                 {moment(assigned_contact.from_date).format(
                                    'DD.MM.YYYY',
                                 )}{' '}
                              </div>
                           </Col>
                           <Col>
                              <div style={{ fontSize: 20 }}>
                                 {moment(assigned_contact.to_date).format(
                                    'DD.MM.YYYY',
                                 )}{' '}
                              </div>
                           </Col>

                           <Col>
                              <div style={{ fontSize: 20 }}>
                                 {assigned_contact.exemption == 1 ? 'Ja' : ''}
                              </div>
                           </Col>
                           <Col>
                              <div style={{ fontSize: 20 }}>
                                 {assigned_contact.correspondence == 1
                                    ? 'Ja'
                                    : ''}
                              </div>
                           </Col>
                           {management && (
                              <Col>
                                 <Button
                                    style={globalStyles.normalButton}
                                    onClick={() => {
                                       this.editAssignedContactClicked(
                                          assigned_contact,
                                       );
                                    }}>
                                    {t(`global.edit`)}
                                 </Button>
                              </Col>
                           )}
                        </Row>
                        <Row>
                           <Col>
                              <div style={{ fontSize: 20 }}>
                                 {assigned_contact.notes}
                              </div>
                           </Col>
                        </Row>
                     </div>
                  );
               })}

            {management && (
               <AddNewButton
                  center
                  label={t(`${i18nPrefix}.addRenter`)}
                  onClick={this.addAssignedContactClicked}
               />
            )}
         </Container>
      );
   }
}

export default withTranslation()(MaintainAssignedContacts);
