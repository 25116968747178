import axios from 'axios';

export const getMyMapOverlays = (callback, errorcallback) => {
   axios
      .get(`/account/get_my_map_overlays`)
      .then((response) => {
         if (response.status == 200) {
            callback(response.data);
         }
      })
      .catch((err) => {
         errorcallback(err);
      });
};
