import PropTypes from 'prop-types';
import React from 'react';
import {
   Alert,
   Button,
   Col,
   Container,
   Form,
   Modal,
   Row,
} from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import COLORS from '../constants/colors';

const styles = {
   label: {
      float: 'left',
      padding: 2,
   },
   labelDisabled: {
      float: 'left',
      padding: 2,
      color: COLORS.MAIN_GRAY,
   },
};

export default function FormCheckbox(props) {
   const { t, i18n } = useTranslation();
   const label = props.label || '';
   const last = !!props.last;
   const { error } = props;
   var errorMessage = '';
   if (error) {
      errorMessage = error == '*' ? '*' : t(`validators.${error}`);
   }
   return (
      <div id={`${props.id}_container`} style={{ marginBottom: 5 }}>
         <Form.Group
            style={{ marginTop: 10, marginBottom: 10, overflow: 'hidden' }}>
            <Form.Check
               style={{
                  float: 'left',
                  margin: 0,
                  paddingLeft: 4,
                  paddingRight: 4,
                  borderColor: COLORS.MAIN_WHITE,
                  borderWidth: 2,
                  borderRadius: 5,
                  borderStyle: 'solid',
               }}
               type='checkbox'
               checked={props.checked}
               onChange={props.onChange}
               disabled={props.disabled}
               id={props.id}
            />
            <div style={props.disabled ? styles.labelDisabled : styles.label}>
               {props.label}{' '}
            </div>
            {props.error && (
               <div style={{ float: 'left', color: COLORS.MAIN_RED }}>
                  {' '}
                  {props.error}{' '}
               </div>
            )}
         </Form.Group>
         {!last && (
            <div
               style={{
                  width: '100%',
                  height: 1,
                  backgroundColor: COLORS.GRAY_OUTLINE,
               }}></div>
         )}
      </div>
   );
}

FormCheckbox.propTypes = {
   label: PropTypes.string.isRequired,
   checked: PropTypes.bool,
   onChange: PropTypes.func,
};
