import moment from 'moment/moment';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import DatePicker from 'react-datepicker';
import { useTranslation } from 'react-i18next';

import COLORS from '../constants/colors';

export default function DateTimeText(props) {
   const { t, i18n } = useTranslation();
   const [showDateSelector, setShowDateSelector] = useState(false);
   const label = props.label || '';
   const last = !!props.last;
   const { error } = props;
   var errorMessage = '';
   if (error) {
      errorMessage = error == '*' ? '*' : t(`validators.${error}`);
   }

   // Value needs to be in YYYY-MM-DD format for date input
   const value = props.value ? moment(props.value).format('YYYY-MM-DD') : '';
   return (
      <div style={{ marginBottom: 5 }}>
         <div>
            <div
               style={{
                  color: COLORS.MAIN_GRAY,
                  alignSelf: 'center',
                  fontSize: 14,
                  float: 'left',
                  width: '100%',
               }}>
               {label.toUpperCase()}
               {props.unit ? ` [${props.unit}]` : ''}
            </div>
            {props.type == 'file' && (
               <div
                  style={{
                     overflow: 'hidden',
                     height: 15,
                     width: '100%',
                  }}></div>
            )}
            {error && (
               <div
                  style={{
                     float: 'right',
                     marginRight: 10,
                     color: COLORS.MAIN_RED,
                     alignSelf: 'center',
                     fontSize: 14,
                     left: 3,
                  }}>
                  {errorMessage}
               </div>
            )}
         </div>

         <div
            style={{
               border: 'none',
               borderRadius: 0,
               paddingLeft: 0,
               marginBottom: -5,
               backgroundColor: 'white',
               color: COLORS.MAIN_BLUE,
               fontWeight: 'bold',
               fontSize: 20,
            }}
            onClick={(e) => {
               e.preventDefault();
               if (!props.disabled) setShowDateSelector(!showDateSelector);
               // other actions
            }}>
            {moment(value).format(t('global.dateFormat'))}
         </div>
         {showDateSelector && (
            <div style={{ position: 'absolute' }}>
               <DatePicker
                  style={{ position: 'absolute', zIndex: 1000 }}
                  selected={new Date(moment(props.value).format('YYYY-MM-DD'))}
                  onChange={(date) => {
                     props.onChange({
                        target: { value: moment(date).format('YYYY-MM-DD') },
                     });
                     setShowDateSelector(false);
                  }}
                  minDate={new Date(props.min)}
                  maxDate={new Date(props.max)}
                  dropdownMode='select'
                  inline
               />
            </div>
         )}

         <div>
            {props.timelabel} {props.time}
         </div>
         {props.type == 'file' && (
            <div style={{ overflow: 'hidden', height: 5, width: '100%' }}></div>
         )}
         {!last && (
            <div
               style={{
                  width: '100%',
                  height: 1,
                  backgroundColor: COLORS.GRAY_OUTLINE,
               }}></div>
         )}
      </div>
   );
}

DateTimeText.propTypes = {
   label: PropTypes.string.isRequired,
};
