import '../stylesheets/segmented-control-react.css';

import PropTypes from 'prop-types';
import React, { Component } from 'react';

export default class SegmentedControl extends Component {
   constructor(props) {
      super(props);
      this.renderSegments = this.renderSegments.bind(this);
   }
   static defaultProps = {
      onChangeSegment: (x) => x,
      variant: 'base',
      selected: null,
   };
   componentDidMount() {
      let selected = this.props.selected;
      // if (
      //    this.props.segments
      //       .map(function (e) {
      //          return e.disabled;
      //       })
      //       .indexOf(true) == selected
      // ) {
      // }
   }

   onChange = (selectedSegment) => {
      if (!this.props.readOnly) {
         this.props.onChangeSegment(selectedSegment);
      }
   };

   renderSegments = () => {
      return this.props.segments.map((segment, i) => {
         if (i === this.props.selected) {
            return (
               <li key={i} className={`${this.props.variant} selected`}>
                  {segment.name || segment}
               </li>
            );
         } else {
            return (
               <li
                  key={i}
                  className={`${this.props.variant}`}
                  onClick={() => this.onChange(i)}>
                  {segment.name || segment}
               </li>
            );
         }
      });
   };

   render() {
      return (
         <div className='r-segmented-control'>
            <ul>{this.renderSegments()}</ul>
         </div>
      );
   }
}

SegmentedControl.propTypes = {
   segments: PropTypes.array.isRequired,
   selected: PropTypes.number.isRequired,
   variant: PropTypes.string,
};
