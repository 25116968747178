import axios from 'axios';

export const getWaitingContacts = (callback, errorcallback) => {
   axios
      .get(`/waiting_contacts/get`)
      .then((response) => {
         if (response.status === 200) {
            callback(response.data);
         }
      })
      .catch((err) => {
         errorcallback(err);
      });
};

export const getWaitingContactsByContactId = (
   contact_id,
   callback,
   errorcallback,
) => {
   axios
      .get(`/waiting_contacts/get_by_contact_id?contact_id=${contact_id}`)
      .then((response) => {
         if (response.status === 200) {
            callback(response.data);
         }
      })
      .catch((err) => {
         errorcallback(err);
      });
};

export function createWaitingContact(waiting_contact, callback, errorCallback) {
   axios
      .post('/waiting_contacts/add', waiting_contact)
      .then((res) => {
         const response = res.data;
         callback(response);
      })
      .catch((error) => {
         errorCallback(error);
      });
}

export function updateWaitingContact(waiting_contact, callback, errorCallback) {
   axios
      .post('/waiting_contacts/update', waiting_contact)
      .then((res) => {
         const response = res.data;
         callback(response);
      })
      .catch((error) => {
         errorCallback(error);
      });
}

export function deleteWaitingContact(id, callback, errorCallback) {
   axios
      .post('/waiting_contacts/delete', { id: id })
      .then((res) => {
         const response = res.data;
         callback(response);
      })
      .catch((error) => {
         errorCallback(error);
      });
}
