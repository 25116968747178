import moment from 'moment';

export const deepSearchString = (objectArray, searchText) => {
   // We compare only lowercase string without spaces
   const searchText_optimized = searchText.replace(/ /g, '').toLowerCase();
   console.log('seardch', searchText);
   const filteredArray = objectArray.filter((entry) =>
      Object.values(entry).some((val) => {
         return (
            typeof val === 'string' &&
            val.toLowerCase().replace(/ /g, '').includes(searchText_optimized)
         );
      }),
   );

   return filteredArray;
};

// Height for auto resize Text Area
export const getTextAreaHeight = (text) => {
   if (!text) return 1;
   return text.split('\n').length;
};

export const getNumberOfDaysBetweenDates = (from_date, to_date) => {
   from_date = moment(from_date);
   to_date = moment(to_date);
   return to_date.diff(from_date, 'days');
};
