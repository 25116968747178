import '../i18n';

import React from 'react';
import { Button, Col, Modal, Row } from 'react-bootstrap';
import { withTranslation } from 'react-i18next';

import DeleteIcon from '../assets/icons/delete.png';
import globalStyles from '../stylesheets/globalStyles';
import FormInput from './FormInput';

const i18nPrefix = 'FilterModal';

class FilterModal extends React.Component {
   constructor(props) {
      super(props);
      this.state = {
         show: false,
         attribute: null,
         min: null,
         max: null,
         defaultFilter: null,
      };
   }

   componentDidMount() {
      this.props.onRef && this.props.onRef(this);
      document.addEventListener('keydown', this.onKeyDown);
   }

   show = (attribute, filter, defaultFilter) => {
      console.log('show', filter.attribute);

      this.setState({
         show: true,
         attribute: attribute,
         min: filter.min,
         max: filter.max,
         defaultFilter: defaultFilter,
      });
   };

   handleShow = () => {
      this.setState({ show: true });
   };

   handleClose = () => {
      this.props.onClose && this.props.onClose();
      this.setState({ show: false });
   };

   onKeyDown = (e) => {
      //enter
      if (this.state.show) {
         if (e.keyCode === 13) {
            this.onClickApply();
         }
      }
   };

   onClickApply = (e) => {
      const filter = {
         min: this.state.min,
         max: this.state.max,
         filter: true,
      };
      this.props.applyFilter &&
         this.props.applyFilter(this.state.attribute, filter);
      this.handleClose();
   };

   render() {
      const { t } = this.props;
      const { attribute, defaultFilter } = this.state;

      return (
         <Modal
            id='modal_filter'
            show={this.state.show}
            onHide={this.handleClose}>
            <Modal.Header closeButton>
               <Modal.Title>{t(`${i18nPrefix}.title`)}</Modal.Title>
            </Modal.Header>

            <Modal.Body>
               <Row>
                  <Col>
                     <FormInput
                        id='input_filter_min'
                        autoFocus
                        type='number'
                        step='0.1'
                        presicion={2}
                        label={t(`${i18nPrefix}.min`)}
                        value={this.state.min}
                        onChange={(event) => {
                           this.setState({ min: event.target.value });
                        }}
                     />
                  </Col>
                  <Col>
                     <FormInput
                        id='input_filter_max'
                        type='number'
                        step='0.1'
                        presicion={2}
                        label={t(`${i18nPrefix}.max`)}
                        value={this.state.max}
                        onChange={(event) => {
                           this.setState({ max: event.target.value });
                        }}
                     />
                  </Col>
               </Row>
            </Modal.Body>
            <Modal.Footer>
               <Button
                  id='button_filter_reset'
                  style={{ ...globalStyles.normalButton }}
                  onClick={() => {
                     const filter = defaultFilter;
                     this.setState({ min: filter.min, max: filter.max });
                     this.props.applyFilter &&
                        this.props.applyFilter(attribute, filter);
                  }}>
                  <img
                     onClick={(e) => {
                        e.stopPropagation();
                        this.filterPressed('max_length');
                     }}
                     style={{ height: 18, marginTop: -2, marginRight: 10 }}
                     src={DeleteIcon}
                     alt='FilterIcon'
                  />
                  {t(`${i18nPrefix}.clear`)}
               </Button>

               <Button
                  id='button_filter_save'
                  style={{ ...globalStyles.normalButton }}
                  type='submit'
                  onSubmit={this.onClickApply}
                  onClick={this.onClickApply}>
                  {t(`${i18nPrefix}.apply`)}
               </Button>
            </Modal.Footer>
         </Modal>
      );
   }
}

export default withTranslation()(FilterModal);
