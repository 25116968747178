import PropTypes from 'prop-types';
import React, { Children } from 'react';
import {
   Alert,
   Button,
   Col,
   Container,
   Form,
   Modal,
   Row,
} from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import COLORS from '../constants/colors';

export default function FormInput(props) {
   const { t, i18n } = useTranslation();
   const label = props.label || '';
   var value = props.value || '';
   if (props.value == null) {
      value = '';
   }
   const last = props.last;
   const { error } = props;
   var errorMessage = '';
   if (error) {
      errorMessage = error == '*' ? '*' : t(`validators.${error}`);
   }
   return (
      <div id={`${props.id}_container`} style={{ marginBottom: 5 }}>
         <div
            style={{
               color: COLORS.MAIN_GRAY,
               alignSelf: 'center',
               fontSize: 14,
               float: 'left',
            }}>
            {label.toUpperCase()}
            {props.unit ? ` [${props.unit}]` : ''}
         </div>
         {props.type == 'file' && (
            <div
               style={{ overflow: 'hidden', height: 15, width: '100%' }}></div>
         )}
         {error && (
            <div
               style={{
                  float: 'right',
                  // color: 'red',
                  marginRight: 10,
                  color: COLORS.MAIN_RED,
                  alignSelf: 'center',
                  fontSize: 14,
                  left: 3,
               }}>
               {errorMessage}
            </div>
         )}
         <Form.Control
            aria-label={props.test_id}
            type={props.type || 'text'}
            step={props.step || 1}
            precicion={props.precision || 2}
            {...props}
            ref={(c) => {
               props.inputRef && props.inputRef(c);
            }}
            style={{
               border: 'none',
               borderRadius: 0,
               paddingLeft: 0,
               backgroundColor: 'white',
               color: COLORS.MAIN_BLUE,
               ...props.style,
            }}
            value={value}
         />
         {props.type == 'file' && (
            <div style={{ overflow: 'hidden', height: 5, width: '100%' }}></div>
         )}
         {!last && (
            <div
               style={{
                  width: '100%',
                  height: 1,
                  backgroundColor: COLORS.GRAY_OUTLINE,
               }}></div>
         )}
      </div>
   );
}

FormInput.defaultProps = {
   value: '',
   onChange: () => {},
   unit: null,
   last: null,
};

FormInput.propTypes = {
   id: PropTypes.string.isRequired,
   label: PropTypes.string.isRequired,
   onChange: PropTypes.elementType,
   value: PropTypes.any,
   unit: PropTypes.string,
   last: PropTypes.bool,
   labelStyle: PropTypes.object,
   as: PropTypes.string,
   type: PropTypes.string,
   children: PropTypes.node,
   rows: PropTypes.number,
   error: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
};
