import React, {
   createRef,
   useCallback,
   useImperativeHandle,
   useRef,
   useState,
} from 'react';
import { Button, Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

type DialogType = {
   show: ({
      title,
      description,
      cancelText,
      onCancelPress,
      okText,
      onOkPress,
   }: {
      title: string;
      description?: string;
      cancelText?: string;
      onCancelPress?: () => void;
      okText?: string;
      onOkPress: () => void;
   }) => void;
};

export const dialogModalRef = createRef<DialogType>();

const DialogModal = () => {
   const { t } = useTranslation();

   const [showModal, setShowModal] = useState(false);
   const [title, setTitle] = useState<string>('');
   const [description, setDescription] = useState<string>('');
   const [cancelText, setCancelText] = useState<string>(t('global.no'));
   const [okText, setOkText] = useState<string>(t('global.yes'));

   const cancelButtonRef = useRef<() => void>();
   const okButtonRef = useRef<() => void>();

   const onClose = useCallback(() => {
      setShowModal(false);
      setTitle('');
      setDescription('');
      setCancelText(t('global.no'));
      setOkText(t('global.yes'));
      cancelButtonRef.current = undefined;
      okButtonRef.current = undefined;
   }, [t]);

   useImperativeHandle(
      dialogModalRef,
      () => {
         return {
            show: ({
               title,
               description,
               cancelText,
               onCancelPress,
               okText,
               onOkPress,
            }) => {
               setTitle(title);
               setDescription(description);
               cancelText && setCancelText(cancelText);
               okText && setOkText(okText);
               cancelButtonRef.current = onCancelPress;
               okButtonRef.current = onOkPress;

               setShowModal(true);
            },
         };
      },
      [],
   );

   return (
      <Modal show={showModal} onHide={onClose}>
         <Modal.Header id='global_dialog_modal' closeButton>
            <Modal.Title>{title}</Modal.Title>
         </Modal.Header>
         <Modal.Body>{description}</Modal.Body>
         <Modal.Footer>
            <Button
               variant='secondary'
               onClick={() => {
                  cancelButtonRef.current?.();
                  onClose();
               }}>
               {cancelText}
            </Button>
            <Button
               variant='primary'
               onClick={() => {
                  okButtonRef.current?.();
                  onClose();
               }}>
               {okText}
            </Button>
         </Modal.Footer>
      </Modal>
   );
};

export default DialogModal;
