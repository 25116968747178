import './index.css';
import 'react-toastify/dist/ReactToastify.css';

import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import { ToastContainer } from 'react-toastify';

import App from './App';
import DialogModal from './components/DialogModal';
import * as serviceWorker from './serviceWorker';

const root = document.getElementById('root');
ReactDOM.createRoot(root).render(
   <Suspense>
      <React.Fragment>
         <App />
         <DialogModal />
         <ToastContainer
            position='bottom-right'
            autoClose={3000}
            closeOnClick={false}
         />
      </React.Fragment>
   </Suspense>,
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
