import '../i18n';

import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Button, Col, FormText, Modal, Row, Spinner } from 'react-bootstrap';
import { withTranslation } from 'react-i18next';

import { createBoat, deleteBoat, updateBoat } from '../actions/boats';
import {
   confirmBooking,
   getBookingDetails,
   updateBooking,
} from '../actions/bookings';
import {
   getBoatDocuments,
   getBoatDocumentToken,
   getBookingReceiptPdfToken,
} from '../actions/documents';
import CancelButton from '../components/CancelButton';
import FormCheckbox from '../components/FormCheckbox';
import FormInputWrapper from '../components/FormInputWrapper';
import PdfModal from '../components/PdfModal';
import StatusAlerts from '../components/StatusAlerts';
import COLORS from '../constants/colors';
import { DATE_FORMAT } from '../constants/format';
import globalStyles from '../stylesheets/globalStyles';
import { getTextAreaHeight } from '../utils/utilities';
import AddNewButton from './AddNewButton';
import AlertModal from './AlertModal';
import DateTimeText from './DateTimeText';
import FormInput from './FormInput';

const i18nPrefix = 'BookingDetailsModal';

class BookingDetailsModal extends React.Component {
   constructor(props) {
      super(props);
      this.state = {
         create: false, //Are we creating or editing a boat
         loading: false,
         success: false,
         error: false,
         bookingDetails: null,
         booking: null,
         boat: null,
         cancel_comment: '',
      };

      this.alertModal = React.createRef();
   }

   componentDidMount() {
      this.props.onRef && this.props.onRef(this);
   }

   show = (booking_id) => {
      if (booking_id) {
         getBookingDetails(
            booking_id,
            (booking_details) => {
               console.log('booking_details', booking_details);
               this.setState({
                  bookingDetails: booking_details,
                  booking: booking_details.booking,
                  booking_time: booking_details.booking_time,
                  boat: booking_details.boat,
                  show: true,
                  cancel_comment: '',
               });
            },
            () => {},
         );
      }
   };
   handleShow = () => {
      this.setState({ show: true });
   };

   handleClose = () => {
      this.props.onClose && this.props.onClose();
      this.setState({ show: false });
   };

   buttonReplaceClicked = () => {
      if (this.props.replaceClicked) {
         this.handleClose();
         this.props.replaceClicked(this.state.bookingDetails);
      }
   };

   buttonSaveClicked = () => {
      const { booking } = this.state;

      const post_object = {
         booking_id: booking.booking_id,
         notes: booking.notes,
         internal_paid: booking.internal_paid,
         from_date: booking.from_date,
         to_date: booking.to_date,
      };
      updateBooking(
         post_object,
         (booking) => {
            this.handleClose();
         },
         (error) => {
            // Alert error from server
            const errorMessage = this.props.t(
               `errors.${error.response.data.language_variable}`,
            );
            this.alertModal.show(
               this.props.t(`errors.error`),
               errorMessage,
               () => {},
            );
         },
      );
   };

   showBoatDocumentsPressed = () => {
      getBoatDocumentToken(
         this.state.bookingDetails.boat_id,
         (token) => {
            this.setState({ pdfToken: token, showPdfModal: true });
         },
         () => {},
      );
   };

   showReceiptPressed = () => {
      getBookingReceiptPdfToken(
         this.state.bookingDetails.booking.booking_id,
         (token) => {
            this.setState({ pdfToken: token, showPdfModalReceipt: true });
         },
         () => {},
      );
   };

   render() {
      const { t } = this.props;
      const { booking } = this.state;

      const bookingDetails = this.state.bookingDetails;
      const selectedBooking = bookingDetails ? bookingDetails.booking : null;
      const rights = selectedBooking ? selectedBooking.rights : null;

      const boat =
         bookingDetails && bookingDetails.boat ? bookingDetails.boat : null;
      const boat_types = t(`global.boatTypes`);
      const boat_drives = t(`global.boatDrives`);

      const extension_date =
         bookingDetails &&
         bookingDetails.booking &&
         bookingDetails.booking.extension
            ? bookingDetails.booking.extension.to_date
            : null;
      const dateRangeText =
         bookingDetails &&
         (extension_date
            ? `${moment(bookingDetails.from_date).format(DATE_FORMAT)} - ${moment(bookingDetails.to_date).format(DATE_FORMAT)} (${moment(extension_date).format(DATE_FORMAT)})`
            : `${moment(bookingDetails.from_date).format(DATE_FORMAT)} - ${moment(bookingDetails.to_date).format(DATE_FORMAT)}`);

      const showReceiptButton = moment(bookingDetails?.to_date).isBefore(
         moment().add(1, 'days'),
      );
      return (
         <Modal
            id='modal_booking_details'
            show={this.state.show}
            onHide={this.handleClose}>
            <Modal.Header closeButton>
               <Modal.Title>{`Booking Details: ${bookingDetails && bookingDetails.booking.booking_id}`}</Modal.Title>
            </Modal.Header>

            {bookingDetails && bookingDetails.boat_id && (
               <PdfModal
                  onClick={() => {}}
                  handleClose={() => {
                     this.setState({ showPdfModal: false });
                  }}
                  show={this.state.showPdfModal}
                  pdfUrl={`${process.env.REACT_APP_API_HOST}boatdocuments/get_my_pdf?token=${this.state.pdfToken}`}
               />
            )}

            {bookingDetails && this.state.showPdfModalReceipt && (
               <PdfModal
                  onClick={() => {}}
                  handleClose={() => {
                     this.setState({ showPdfModalReceipt: false });
                  }}
                  show={this.state.showPdfModalReceipt}
                  pdfUrl={`${process.env.REACT_APP_API_HOST}bookings/get_receipt_pdf?token=${this.state.pdfToken}`}
               />
            )}

            <AlertModal onRef={(ref) => (this.alertModal = ref)} />

            {bookingDetails != null && (
               <Modal.Body>
                  <Row>
                     <Col>
                        <DateTimeText
                           id={'input_from_date'}
                           min={this.state.booking_time?.from_date}
                           max={moment(
                              this.state.booking_time?.to_date,
                           ).subtract(1, 'day')}
                           disabled={!booking.own}
                           label={t(`FastBooking.from`)}
                           value={booking.from_date}
                           timelabel={t(`FastBooking.checkInTime`)}
                           time={this.state.bookingDetails.mooring?.check_in_time.slice(
                              0,
                              -3,
                           )}
                           onChange={(event) => {
                              this.setState({
                                 booking: {
                                    ...this.state.booking,
                                    from_date: moment(
                                       event.target.value,
                                    ).format('YYYY-MM-DD'),
                                 },
                              });
                           }}
                        />
                     </Col>
                     <Col>
                        <DateTimeText
                           id={'input_to_date'}
                           min={moment(booking.from_date).add(1, 'day')}
                           max={moment(this.state.booking_time?.to_date)}
                           disabled={!booking.own}
                           label={t(`FastBooking.to`)}
                           value={booking.to_date}
                           timelabel={t(`FastBooking.checkOutTime`)}
                           time={this.state.bookingDetails.mooring?.check_out_time.slice(
                              0,
                              -3,
                           )}
                           onChange={(event) => {
                              this.setState({
                                 booking: {
                                    ...this.state.booking,
                                    to_date: moment(event.target.value).format(
                                       'YYYY-MM-DD',
                                    ),
                                 },
                              });
                           }}
                        />
                     </Col>
                  </Row>

                  {/* <FormInput
            readOnly
            type="text"
            label={t('MooringDetails.from') + " - " + t('MooringDetails.to')}
            value={dateRangeText}
          /> */}
                  <FormInput
                     id='input_name'
                     readOnly
                     type='text'
                     label={t('MaintainTimesAndPrices.name')}
                     value={`${bookingDetails.firstname} ${bookingDetails.lastname}`}
                  />

                  <FormInputWrapper label={t('MaintainTimesAndPrices.email')}>
                     <a
                        id={'link_email'}
                        href={`mailto:${bookingDetails.email}`}>
                        {bookingDetails.email}
                     </a>
                  </FormInputWrapper>

                  <FormInputWrapper label={t('MaintainTimesAndPrices.mobile')}>
                     <a
                        id={'link_mobile'}
                        href={`tel:${bookingDetails.mobilenumber}`}>
                        {bookingDetails.mobilenumber}
                     </a>
                  </FormInputWrapper>

                  {boat && (
                     <>
                        {(boat?.registration_number != '',
                        boat?.registration_number != null) && (
                           <FormInput
                              id={'input_boat_registration_number'}
                              label={t(
                                 `MaintainTimesAndPrices.boatRegistrationNumber`,
                              )}
                              value={`${bookingDetails.boat?.registration_number} `}
                              editable={false}
                              pointerEvents='none'
                              last={
                                 bookingDetails.boat?.registration_number == ''
                              }
                           />
                        )}
                        {boat?.length && (
                           <FormInput
                              id={'input_boat_dimensions'}
                              label={t(`MaintainTimesAndPrices.boatDimensions`)}
                              value={`${bookingDetails.boat?.length} / ${bookingDetails.boat?.beam} / ${bookingDetails.boat?.draft} m`}
                              editable={false}
                              pointerEvents='none'
                              last
                           />
                        )}
                        {boat?.name != '' && boat?.name != null && (
                           <FormInput
                              id={'input_boat_name'}
                              label={t(`MaintainTimesAndPrices.boatName`)}
                              value={`${bookingDetails.boat?.name} `}
                              editable={false}
                              pointerEvents='none'
                              last={bookingDetails.boat?.name == ''}
                           />
                        )}
                        {boat?.make != '' && boat?.make != null && (
                           <FormInput
                              id={'input_make'}
                              label={t(`EditBoatModal.make`)}
                              value={`${bookingDetails.boat?.make} `}
                              editable={false}
                              pointerEvents='none'
                              last={bookingDetails.boat?.make == ''}
                           />
                        )}
                        {boat?.type !== 0 &&
                           boat?.type !== '' &&
                           boat?.type != null && (
                              <FormInput
                                 id={'input_type'}
                                 label={t(`EditBoatModal.type`)}
                                 value={`${boat_types[boat?.type]} `}
                                 editable={false}
                                 pointerEvents='none'
                                 last={bookingDetails.boat?.type == ''}
                              />
                           )}
                        {Boolean(boat?.drive) &&
                           boat?.drive != '' &&
                           boat?.drive != null && (
                              <FormInput
                                 id={'input_drive'}
                                 label={t(`EditBoatModal.drive`)}
                                 value={`${boat_drives[boat?.drive]} `}
                                 editable={false}
                                 pointerEvents='none'
                                 last={bookingDetails.boat?.drive == ''}
                              />
                           )}{' '}
                        {boat && boat?.weight && (
                           <FormInput
                              id={'input_weight'}
                              label={t(`EditBoatModal.weight`)}
                              value={`${bookingDetails.boat?.weight} `}
                              editable={false}
                              pointerEvents='none'
                              last={bookingDetails.boat?.weight == ''}
                           />
                        )}
                     </>
                  )}

                  <FormCheckbox
                     id='checkbox_internal_paid'
                     label={t(`${i18nPrefix}.internal_paid`)}
                     checked={
                        Boolean(this.state.booking.payment_status) &&
                        Boolean(this.state.booking.internal_paid)
                     }
                     onChange={(e) => {
                        this.setState({
                           booking: {
                              ...this.state.booking,
                              internal_paid: e.target.checked,
                           },
                        });
                     }}
                     disabled={!rights.update || !this.state.booking.own}
                  />

                  <FormInput
                     id={'input_notes'}
                     as='textarea'
                     type='text'
                     label={t('MooringDetails.notes')}
                     value={`${this.state.booking.notes}`}
                     rows={getTextAreaHeight(this.state.booking.notes)}
                     onChange={(e) => {
                        this.setState({
                           booking: {
                              ...this.state.booking,
                              notes: e.target.value,
                           },
                        });
                     }}
                  />

                  {Boolean(
                     bookingDetails.boat_id && bookingDetails.documents,
                  ) && (
                     <Button
                        style={{ ...globalStyles.normalButton }}
                        onClick={this.showBoatDocumentsPressed}>
                        {t('MaintainTimesAndPrices.showBoatDocuments')}
                     </Button>
                  )}
                  {showReceiptButton && (
                     <Button
                        style={globalStyles.normalButton}
                        onClick={this.showReceiptPressed}>
                        {t('BookingDetailsModal.showReceipt')}
                     </Button>
                  )}

                  {!bookingDetails.booking.confirmed && rights.update && (
                     <>
                        <Row>
                           <FormInput
                              id={'cancel_comment'}
                              type='text'
                              label={t(`${i18nPrefix}.cancel_comment`)}
                              value={this.state.cancel_comment}
                              onChange={(e) => {
                                 this.setState({
                                    cancel_comment: e.target.value,
                                 });
                              }}
                           />
                           <Col md={6}>
                              <Button
                                 id='button_accept'
                                 style={{
                                    ...globalStyles.normalButton,
                                    color: COLORS.MAIN_WHITE,
                                    backgroundColor:
                                       COLORS.GREEN_MOORING_AVAILABLE,
                                 }}
                                 onClick={() => {
                                    this.setState(
                                       { loading_accept: true },
                                       () => {
                                          confirmBooking(
                                             bookingDetails.booking.booking_id,
                                             true,
                                             this.state.cancel_comment,
                                             (response) => {
                                                this.handleClose();
                                             },
                                             (error) => {
                                                this.alertModal.show(
                                                   t(
                                                      `${i18nPrefix}.paymentFailedTitle`,
                                                   ),
                                                   t(
                                                      `${i18nPrefix}.paymentFailed`,
                                                   ),
                                                   () => {},
                                                );
                                             },
                                             () => {
                                                this.setState({
                                                   loading_accept: false,
                                                });
                                             },
                                          );
                                       },
                                    );
                                 }}
                                 disabled={
                                    this.state.loading_accept ||
                                    this.state.loading_decline
                                 }>
                                 {this.state.loading_accept && (
                                    <Spinner
                                       style={{ width: 20, height: 20 }}
                                       animation='border'
                                       variant='secondary'
                                       role='status'
                                    />
                                 )}
                                 {t(`global.accept`)}
                              </Button>
                           </Col>
                           <Col md={6}>
                              <Button
                                 id='button_reject'
                                 style={{
                                    ...globalStyles.normalButton,
                                    color: COLORS.MAIN_WHITE,
                                    backgroundColor:
                                       COLORS.RED_MOORING_UNAVAILABLE,
                                 }}
                                 onClick={() => {
                                    this.setState(
                                       { loading_decline: true },
                                       () => {
                                          confirmBooking(
                                             bookingDetails.booking.booking_id,
                                             false,
                                             this.state.cancel_comment,
                                             (response) => {
                                                this.handleClose();
                                             },
                                             (error) => {},
                                             () => {
                                                this.setState({
                                                   loading_decline: false,
                                                });
                                             },
                                          );
                                       },
                                    );
                                 }}
                                 disabled={
                                    this.state.loading_accept ||
                                    this.state.loading_decline
                                 }>
                                 {this.state.loading_decline && (
                                    <Spinner
                                       style={{ width: 20, height: 20 }}
                                       animation='border'
                                       variant='secondary'
                                       role='status'
                                    />
                                 )}

                                 {t(`global.decline`)}
                              </Button>
                           </Col>
                        </Row>
                     </>
                  )}

                  {Boolean(rights.replace) &&
                     Boolean(bookingDetails.booking.confirmed) && (
                        <Button
                           id='button_replace'
                           style={{ ...globalStyles.normalButton }}
                           onClick={this.buttonReplaceClicked}>
                           {t(`${i18nPrefix}.replace`)}
                           {this.state.loading && (
                              <Spinner
                                 style={{ width: 20, height: 20 }}
                                 animation='border'
                                 variant='secondary'
                                 role='status'
                              />
                           )}
                        </Button>
                     )}

                  {rights.cancel && (
                     <CancelButton
                        id='button_cancel'
                        key={selectedBooking.booking_id}
                        booking={selectedBooking}
                        onCancelSuccess={() => {
                           this.setState({ selectedBooking: false });
                           this.handleClose();
                        }}
                     />
                  )}

                  {rights.update && (
                     <Button
                        id='button_save'
                        style={{
                           ...globalStyles.normalButton,
                           ...globalStyles.saveButtonGreen,
                        }}
                        onClick={this.buttonSaveClicked}>
                        {t(`global.save`)}
                        {this.state.loading && (
                           <Spinner
                              style={{ width: 20, height: 20 }}
                              animation='border'
                              variant='secondary'
                              role='status'
                           />
                        )}
                     </Button>
                  )}
               </Modal.Body>
            )}
         </Modal>
      );
   }
}

export default withTranslation()(BookingDetailsModal);
