import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Col, Container, Modal, Row } from 'react-bootstrap';
import DataGrid, { type DataGridProps, type SortColumn } from 'react-data-grid';
import { useTranslation } from 'react-i18next';

import { getMyContacts } from '../actions/contacts';
import COLORS from '../constants/colors';
import type { Contact } from '../types/Contact';
import { deepSearchString } from '../utils/utilities';

const i18nPrefix = 'ContactTable';

type ContactDataGridProps = DataGridProps<Contact>;

type Props = {
   showModal: boolean;
   hideModal: () => void;
   onSelectContact: (contact: Contact) => void;
};
const ContactListModal = ({ showModal, hideModal, onSelectContact }: Props) => {
   const { t: translate } = useTranslation();
   const [contacts, setContacts] = useState<Contact[]>();
   const [sortedContacts, setSortedContacts] = useState<Contact[]>([]);
   const [columnSortings, setColumnSortings] = useState<SortColumn[]>();
   const [searchText, setSearchText] = useState('');
   const searchInputRef = useRef();

   useEffect(() => {
      getMyContacts(
         (contacts: Contact[]) => {
            console.log('getMyContacts', searchText, columnSortings);

            const filtered = getFilteredRows(contacts, searchText);
            const sorted_filtered = getSortedRows(filtered, columnSortings);
            setContacts(contacts);
            setSortedContacts(sorted_filtered);
         },
         (err) => {
            console.error(err);
         },
      );
   }, [columnSortings, searchText]);

   const getFilteredRows = (contacts: Contact[], sText: string) => {
      let fContacts = [...contacts];
      if (sText) {
         fContacts = deepSearchString(fContacts, sText);
      }
      return fContacts;
   };

   const getSortedRows = (contacts: Contact[], colSortings: SortColumn[]) => {
      const sortedContacts = [...contacts];
      if (colSortings && colSortings[0]) {
         const columnKey = colSortings[0].columnKey;
         const direction = colSortings[0].direction;
         sortedContacts.sort(function (a, b) {
            const comparison = a[columnKey] > b[columnKey] ? 1 : -1;
            return direction === 'ASC' ? comparison : -comparison;
         });
      }
      return sortedContacts;
   };

   const onSortColumnsChange: ContactDataGridProps['onSortColumnsChange'] = (
      colSortings,
   ) => {
      console.log('OnSortColumnsChange', colSortings, searchText);

      setColumnSortings(colSortings);

      const filtered = getFilteredRows(contacts, searchText);
      const sorted_filtered = getSortedRows(filtered, colSortings);

      setSortedContacts(sorted_filtered);
   };

   const onRowClick: ContactDataGridProps['onRowClick'] = (contact, arg2) => {
      console.log('onRowClick: ', contact, arg2);
   };

   const onRowDoubleClick: ContactDataGridProps['onRowDoubleClick'] = (
      contact,
      arg2,
   ) => {
      console.log('onRowDoubleClick: ', contact, arg2);
      onSelectContact(contact);
   };

   const columns = useMemo(() => {
      return [
         {
            key: 'firstname',
            name: translate(`${i18nPrefix}.firstname`),
            width: 120,
            frozen: true,
         },
         {
            key: 'lastname',
            name: translate(`${i18nPrefix}.lastname`),
            width: 120,
            frozen: true,
         },
         {
            key: 'email',
            name: translate(`${i18nPrefix}.email`),
            width: 200,
         },
         {
            key: 'mobile',
            name: translate(`${i18nPrefix}.mobile`),
            width: 180,
         },
         {
            key: 'communication_language',
            name: translate(`${i18nPrefix}.communication_language`),
            width: 50,
         },
         {
            key: 'street',
            name: translate(`${i18nPrefix}.street`),
            width: 120,
         },
         {
            key: 'zip',
            name: translate(`${i18nPrefix}.zip`),
            width: 50,
         },
         {
            key: 'city',
            name: translate(`${i18nPrefix}.city`),
            width: 120,
         },
         {
            key: 'type',
            name: translate(`${i18nPrefix}.type`),
            width: 150,
         },
         {
            key: 'birthday',
            name: translate(`${i18nPrefix}.birthday`),
            width: 150,
         },
         {
            key: 'access_badge',
            name: translate(`${i18nPrefix}.access_badge`),
            width: 150,
         },
         {
            key: 'remarks',
            name: translate(`${i18nPrefix}.remarks`),
            width: 150,
         },
         {
            key: 'token',
            name: translate(`${i18nPrefix}.token`),
            width: 150,
         },
         {
            key: 'customer_number',
            name: translate(`${i18nPrefix}.customer_number`),
            width: 150,
         },
         {
            key: 'id',
            name: translate(`${i18nPrefix}.id`),
            width: 150,
         },
      ];
   }, [translate]);

   return (
      <Modal animation={false} size='xl' show={showModal} onHide={hideModal}>
         <Modal.Header closeButton>
            <Modal.Title>
               {translate(`${i18nPrefix}.selectContact`)}
            </Modal.Title>
         </Modal.Header>
         <Modal.Body>
            <Container style={{ maxHeight: 'none' }}>
               <Row>
                  <Col sm={9}>
                     <h1>{'Kontakte'}</h1>
                     <h2>{'Auswählen'}</h2>
                  </Col>
               </Row>

               <Row>
                  <Col md={9}>
                     <input
                        style={{
                           marginTop: 0,
                           outline: 'none',
                           marginBottom: 10,
                           width: '100%',
                           fontSize: 20,
                           border: 'none',
                           borderRadius: 0,
                           paddingLeft: 0,
                           backgroundColor: 'white',
                           color: COLORS.MAIN_BLUE,
                           boxShadow: 'none',
                        }}
                        autoFocus={true}
                        ref={searchInputRef}
                        placeholder={translate(`global.search`)}
                        value={searchText}
                        onChange={(e) => {
                           setSearchText(e.target.value);
                        }}
                     />
                     <div
                        style={{
                           width: '100%',
                           height: 1,
                           backgroundColor: COLORS.GRAY_OUTLINE,
                        }}></div>
                  </Col>
               </Row>
               <div style={{ marginTop: 10 }}></div>

               <DataGrid
                  style={{ maxHeight: 'none', height: 700 }}
                  columns={columns}
                  rows={sortedContacts}
                  className='rdg-light'
                  rowKeyGetter={(contact) => contact.id}
                  defaultColumnOptions={{
                     sortable: true,
                     resizable: true,
                  }}
                  onSortColumnsChange={onSortColumnsChange}
                  sortColumns={columnSortings}
                  onRowClick={onRowClick}
                  onRowDoubleClick={onRowDoubleClick}
               />
            </Container>
         </Modal.Body>
      </Modal>
   );
};

export default ContactListModal;
