import moment from 'moment';
import React from 'react';
import {
   Alert,
   Button,
   Col,
   Collapse,
   Container,
   FormControl,
   Row,
   Spinner,
} from 'react-bootstrap';
import { withTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { cancelBooking } from '../actions/bookings';
import p_active from '../assets/maintain_bookings_screen/p_active.png';
import p_inactive from '../assets/maintain_bookings_screen/p_inactive.png';
import CancelButton from '../components/CancelButton';
import FormInput from '../components/FormInput';
import COLORS from '../constants/colors';
import { DATE_FORMAT } from '../constants/format';
import { getTextAreaHeight } from '../utils/utilities';

class BookingItem extends React.Component {
   constructor(props) {
      super(props);

      this.state = {
         loading: false,
         success: false,
         error: false,
         showDetails: false,
      };
   }

   handleCancelClick = () => {
      this.setState({ loading: true }, () => {
         cancelBooking(
            this.props.booking,
            this.cancelBookingSuccess,
            this.cancelBookingFail,
         );
      });
   };

   cancelBookingSuccess = () => {
      this.setState({ loading: false }, () => {
         this.props.onCancelSuccess();
      });
   };

   cancelBookingFail = () => {
      this.setState({ loading: false });
   };

   renderBookingDetails = (booking) => {
      const { t } = this.props;

      return (
         <div id='example-collapse-text'>
            <FormInput
               readOnly
               type='text'
               label={t('MooringDetails.from') + ' - ' + t('MooringDetails.to')}
               value={`${moment(booking.from_date).format(DATE_FORMAT)} - ${moment(booking.to_date).format(DATE_FORMAT)}`}
            />
            <FormInput
               readOnly
               type='text'
               label={t('MaintainTimesAndPrices.name')}
               value={`${booking.firstname} ${booking.lastname}`}
            />
            <FormInput
               readOnly
               type='text'
               label={t('MaintainTimesAndPrices.email')}
               value={booking.email}
            />
            <FormInput
               readOnly
               type='text'
               label={t('MaintainTimesAndPrices.mobile')}
               value={booking.mobilenumber}
            />
            {booking.notes && (
               <FormInput
                  as='textarea'
                  readOnly
                  type='text'
                  label={t('MooringDetails.notes')}
                  value={`${booking.notes}`}
                  rows={getTextAreaHeight(booking.notes)}
               />
            )}

            {booking.boat_registration_number != '' &&
               booking.boat_registration_number != null && (
                  <FormInput
                     label={t(`MaintainTimesAndPrices.boatRegistrationNumber`)}
                     value={`${booking.boat_registration_number} `}
                     editable={false}
                     pointerEvents='none'
                     last={booking.boat_registration_number == ''}
                  />
               )}
            {booking.boat_length != '' && booking.boat_length != null && (
               <FormInput
                  label={t(`MaintainTimesAndPrices.boatDimensions`)}
                  value={`${booking.boat_length} / ${booking.boat_beam} / ${booking.boat_draft} m`}
                  editable={false}
                  pointerEvents='none'
                  last
               />
            )}

            <CancelButton
               booking={booking}
               onCancelSuccess={() => {
                  this.props.reload();
               }}
            />
         </div>
      );
   };

   render() {
      const { t } = this.props;
      const { booking } = this.props;

      const from_date = moment(booking.from_date);
      const to_date = moment(booking.to_date);
      return (
         <div>
            <div
               style={{
                  backgroundColor: 'white',
                  borderRadius: 5,
                  overflow: 'visible',
                  boxSizing: 'border-box',
                  width: 'auto',
                  padding: 10,
                  marginBottom: 10,
               }}>
               <div>
                  <h3>{booking.reservation_name}</h3>
                  {booking.booking_id}
                  <div style={{ marginBottom: 10 }}>
                     {from_date.format(DATE_FORMAT)} -{' '}
                     {to_date.format(DATE_FORMAT)}
                  </div>

                  <div
                     style={{
                        marginBottom: 10,
                     }}>{`${booking.firstname} ${booking.lastname}`}</div>

                  {booking.notes && (
                     <div style={{ marginBottom: 10 }}>{booking.notes}</div>
                  )}
                  <Button
                     onClick={() => {
                        this.setState({ showDetails: !this.state.showDetails });
                     }}>
                     Details
                  </Button>

                  <Collapse in={this.state.showDetails}>
                     <div id='example-collapse-text'>
                        {this.renderBookingDetails(booking)}
                     </div>
                  </Collapse>
               </div>
            </div>
         </div>
      );
   }
}

const styles = {
   button: {
      width: '100%',
      backgroundColor: 'white',
      color: COLORS.MAIN_GRAY,
      fontWeight: 'bold',
      borderColor: COLORS.MAIN_GRAY,
      marginRight: 10,
   },
};

export default withTranslation()(BookingItem);
