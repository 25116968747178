import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Col, Modal, Row, Spinner } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import {
   deleteDocument,
   getDocumentToken,
   updateDocument,
} from '../actions/documents';
import globalStyles from '../stylesheets/globalStyles';
import type { Document } from '../types/Document';
import FormInput from './FormInput';

const i18nPrefix = 'MaintainDocuments';

type Props = {
   onRef: React.MutableRefObject<
      | {
           show: (document: Document) => void;
        }
      | undefined
   >;
   onClose?: () => void;
   pdfUrl?: string | null;
};

export default function ShowDocumentModal(props: Props) {
   const [showModal, setShowModal] = useState(false);
   const [document, setDocument] = useState(null);
   const [documentToken, setDocumentToken] = useState(null);

   const [loading, setLoading] = useState(false);
   const [title, setTitle] = useState('');
   const [description, setDescription] = useState('');

   const { t } = useTranslation();

   useEffect(() => {
      if (props.onRef) {
         props.onRef.current = { show };
      }
      return () => {
         if (props.onRef) {
            props.onRef.current = undefined;
         }
      };
   }, [props.onRef]);

   const show = (document) => {
      setDocument(document);
      setTitle(document.title);
      setDescription(document.description);

      getDocumentToken(
         document.id,
         (token) => {
            console.log('GOT TOKEN', token);
            setDocumentToken(token);
         },
         () => {},
         () => {},
      );

      setShowModal(true);
   };

   const handleClose = () => {
      props.onClose && props.onClose();
      setDocumentToken(null);
      setDocument(null);
      setShowModal(false);
   };

   const updateButtonClicked = () => {
      const post = {
         id: document?.id,
         title: title,
         description: description,
      };
      setLoading(true);
      updateDocument(
         post,
         () => {
            //Succes
            handleClose();
         },
         () => {},
         () => {
            //finally
            setLoading(false);
         },
      );
   };
   const deleteButtonClicked = () => {
      const userConfirmed = window.confirm(t(`${i18nPrefix}.delete_confirm`));

      if (userConfirmed) {
         setLoading(true);
         deleteDocument(
            document?.id,
            () => {
               //Success
               handleClose();
            },
            () => {},
            () => {
               //finally
               setLoading(false);
            },
         );
      }
   };

   const url = props.pdfUrl
      ? props.pdfUrl
      : `${process.env.REACT_APP_API_HOST}documents/get_by_token?token=${documentToken}`;
   // if (!document) return <>sdfsdfshsdfjhskjdhfskjdhfksdjhfjksdhfjhfjksfdhffhsfdjshfkshfkdshfjksdhjfhjskdfsdfsdfdsf</>
   return (
      <>
         <Modal
            show={showModal}
            onHide={handleClose}
            dialogClassName='modal-80w'
            aria-labelledby='example-custom-modal-styling-title'>
            <Modal.Header closeButton>
               <div></div>
            </Modal.Header>
            <Modal.Body>
               <div
                  style={{
                     display: 'flex',
                     width: '100%',
                     justifyContent: 'space-between',
                     alignItems: 'center',
                  }}>
                  <FormInput
                     label='Title'
                     value={title}
                     onChange={(e) => {
                        setTitle(e.target.value);
                     }}
                  />
                  <button
                     style={{
                        ...globalStyles.normalButton,
                        ...globalStyles.saveButtonGreen,
                        float: 'right',
                        maxWidth: 200,
                     }}
                     onClick={updateButtonClicked}>
                     {t(`${i18nPrefix}.update`)}
                     {loading && (
                        <Spinner
                           style={{ width: 20, height: 20, marginLeft: 10 }}
                           animation='border'
                           variant='secondary'
                           role='status'
                        />
                     )}
                  </button>
               </div>
               <Row>
                  <FormInput
                     as='textarea'
                     label='Description'
                     value={description}
                     onChange={(e) => {
                        setDescription(e.target.value);
                     }}
                  />
               </Row>
               {documentToken &&
                  (document?.path?.endsWith('.pdf') ? (
                     <object
                        width='100%'
                        height='700'
                        data={url}
                        type='application/pdf'></object>
                  ) : (
                     <img
                        src={url}
                        alt='Document'
                        style={{ width: '100%', height: 'auto' }}
                     />
                  ))}

               <button
                  style={{
                     ...globalStyles.normalButton,
                     ...globalStyles.deleteButton,
                     float: 'right',
                     maxWidth: 200,
                  }}
                  onClick={deleteButtonClicked}>
                  {t(`${i18nPrefix}.delete`)}
                  {loading && (
                     <Spinner
                        style={{ width: 20, height: 20, marginLeft: 10 }}
                        animation='border'
                        variant='secondary'
                        role='status'
                     />
                  )}
               </button>
            </Modal.Body>
         </Modal>
      </>
   );
}

ShowDocumentModal.propTypes = {
   onRef: PropTypes.object.isRequired,
   onClose: PropTypes.func.isRequired,
   document: PropTypes.string.isRequired,
   setDocument: PropTypes.func.isRequired,
   pdfUrl: PropTypes.string.isRequired,
};
