// import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';

import COLORS from '../constants/colors';

// variable?: string -> optional string prop, if not sent it's undefined as default
type Props = {
   id?: string | number;
   label: string;
   value?: string;
   type?: 'file' | string;
   unit?: any;
   last: boolean;
   error?: string;
   children?: React.ReactNode;
};
export default function FormValueContainer({
   id,
   label,
   // value = '', // not used
   type,
   unit,
   last,
   error,
   children,
}: Props) {
   const { t } = useTranslation();

   /* var value = props.value || '';
   if (props.value == null) {
      value = '';
   }*/

   const errorMessage = error
      ? error == '*'
         ? '*'
         : t(`validators.${error}`)
      : '';

   return (
      <div id={`${id}_container`} style={{ marginBottom: 5 }}>
         <div
            style={{
               color: COLORS.MAIN_GRAY,
               alignSelf: 'center',
               fontSize: 14,
               float: 'left',
               width: '100%',
            }}>
            {label.toUpperCase()}
            {unit ? ` [${unit}]` : ''}
         </div>
         {type == 'file' && (
            <div
               style={{ overflow: 'hidden', height: 15, width: '100%' }}></div>
         )}
         {error && (
            <div
               style={{
                  float: 'right',
                  // color: 'red', // TODO: check! color defined 2 times which one is correct??
                  marginRight: 10,
                  color: COLORS.MAIN_RED,
                  alignSelf: 'center',
                  fontSize: 14,
                  left: 3,
               }}>
               {errorMessage}
            </div>
         )}
         <div style={{ display: 'block', clear: 'both' }}>
            {children} {/* Render children here */}
         </div>
         {!last && (
            <div
               style={{
                  width: '100%',
                  height: 1,
                  backgroundColor: COLORS.GRAY_OUTLINE,
               }}></div>
         )}
      </div>
   );
}

/*FormValueContainer.defaultProps = {
   value: '',
   onChange: () => {},
   unit: null,
   last: null,
};

FormValueContainer.propTypes = {
   label: PropTypes.string.isRequired,
};*/
