import '../i18n';

import axios from 'axios';
import React from 'react';
import { Button, Container } from 'react-bootstrap';
import { withTranslation } from 'react-i18next';

import { getAccountDataByToken } from '../actions/account';
import {
   resetPassword,
   updateAndResendActivationEmail,
} from '../actions/register';
import FormInput from '../components/FormInput';
import StatusAlerts from '../components/StatusAlerts';
// import i18n from '../i18n';
import { validateResetPasswordForm } from '../utils/validators';

const i18nPrefix = 'ResetPasasword';

class ResendEmail extends React.Component {
   constructor(props) {
      super(props);
      this.state = {
         loading: false,
         success: false,
         error: false,
         account_token: '',
         email: '',
         mobile: '',
      };
   }

   componentDidMount() {
      // Check if we are registered with a account_token
      if (this.props.match.params.token) {
         const token = this.props.match.params.token;
         this.setState({ token: token });
      }
   }

   handleResendClick = () => {
      const data = {
         token: this.state.token,
         password: this.state.password,
      };
      const formErrors = validateResetPasswordForm(this.state);
      this.setState({ loading: true, success: false, error: false }, () => {
         resetPassword(
            data,
            (response) => {
               this.setState({
                  loading: false,
                  success: true,
                  error: false,
                  successMessage: this.props.t(
                     `${i18nPrefix}.paswordHasBeenReset`,
                     { email: this.state.email },
                  ),
               });
            },
            (err) => {
               this.setState({
                  loading: false,
                  uccess: false,
                  error: true,
                  errorMessage: this.props.t('errors.somethingWentWrong'),
               });
            },
         );
      });
   };

   render() {
      const { t } = this.props;
      const formErrors = validateResetPasswordForm(this.state);

      return (
         <Container
            style={{
               maxWidth: 800,
               marginTop: 50,
               borderRadius: 5,
               backgroundColor: 'white',
            }}>
            <h1>{t(`${i18nPrefix}.title`)}</h1>
            <FormInput
               type='password'
               label={t(`${i18nPrefix}.password`)}
               value={this.state.password}
               error={formErrors.password}
               onChange={(event) =>
                  this.setState({ password: event.target.value })
               }
            />
            <FormInput
               type='password'
               label={t(`${i18nPrefix}.passwordRepeat`)}
               value={this.state.repeatPassword}
               error={formErrors.repeatPassword}
               onChange={(event) =>
                  this.setState({ repeatPassword: event.target.value })
               }
            />
            {!this.state.success && (
               <Button
                  disabled={this.state.loading}
                  variant='primary'
                  type='submit'
                  onClick={() => this.handleResendClick()}>
                  {t(`${i18nPrefix}.reset`)}
               </Button>
            )}

            <StatusAlerts
               state={this.state}
               successMessage={this.state.successMessage}
               errorMessage={this.state.errorMessage}
            />
         </Container>
      );
   }
}

export default withTranslation()(ResendEmail);
