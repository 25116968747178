import axios from 'axios';
import moment from 'moment';

export function addBooking(booking, callback, errorcallback) {
   booking.from_date = moment(booking.from_date).format('YYYY-MM-DD');
   booking.to_date = moment(booking.to_date).format('YYYY-MM-DD');

   axios
      .post('bookings/add_booking', booking)
      .then((response) => {
         if (response.status == 200) {
            var booking = response.data.booking;
            callback(booking);
         }
      })
      .catch((err) => {
         errorcallback(err);
      });
}

export function cancelBooking(booking, callback, errorcallback) {
   axios
      .post(`bookings/cancel_booking`, booking)
      .then((response) => {
         if (response.status == 200) {
            callback();
         }
      })
      .catch((err) => {
         errorcallback(err.response);
      });
}

export function replace(booking_id, mooring_id, callback, errorcallback) {
   axios
      .post('bookings/replace', {
         booking_id: booking_id,
         mooring_id: mooring_id,
      })
      .then((response) => {
         if (response.status == 200) {
            callback(response.data);
         }
      })
      .catch((err) => {
         errorcallback(err.response);
      });
}

export const getBookingDetails = (booking_id, callback, errorcallback) => {
   axios
      .get(`bookings/get_booking_details?id=${booking_id}`)
      .then((response) => {
         if (response.status == 200) {
            const bookingDetails = response.data;
            callback(bookingDetails);
         }
      })
      .catch((err) => {
         errorcallback(err);
      });
};

export const updateBooking = (booking, callback, errorcallback) => {
   axios
      .post(`bookings/update`, booking)
      .then((response) => {
         if (response.status == 200) {
            const booking = response.data;
            callback(booking);
         }
      })
      .catch((err) => {
         errorcallback(err);
      });
};

export const confirmBooking = (
   booking_id,
   accept,
   cancel_comment = '',
   callback = () => {},
   errorcallback = () => {},
   finnalycallback = () => {},
) => {
   const data = {
      booking_id: booking_id,
      accept: accept,
      cancel_comment: cancel_comment,
   };
   axios
      .post(`bookings/confirm_booking_admin`, data)
      .then((response) => {
         if (response.status == 200) {
            callback(response.data);
         }
      })
      .catch((err) => {
         errorcallback(err);
      })
      .finally(() => {
         finnalycallback();
      });
};

export const getMyReservations = (
   from_date,
   to_date,
   callback,
   errorcallback,
) => {
   axios
      .get(
         `bookings/get_my_reservations?from_date=${from_date}&to_date=${to_date}`,
      )
      .then((response) => {
         if (response.status == 200) {
            const reservations = response.data;
            callback(reservations);
         }
      })
      .catch((err) => {
         callback(err);
      });
};

export function addBookingUnregistered(booking, callback, errorcallback) {
   booking.from_date = moment(booking.from_date).format('YYYY-MM-DD');
   booking.to_date = moment(booking.to_date).format('YYYY-MM-DD');

   axios
      .post('bookings/add_booking_unregistered', booking)
      .then((response) => {
         if (response.status == 200) {
            callback(response.data);
         }
      })
      .catch((err) => {
         errorcallback(err);
      });
}

export const getBookingPrice = (
   mooring_id,
   from_date,
   to_date,
   boat_length,
   boat_beam,
   number_of_adults,
   number_of_children,
   callback,
   errorcallback,
) => {
   from_date = moment(from_date).format('YYYY-MM-DD');
   to_date = moment(to_date).format('YYYY-MM-DD');

   const object = {
      mooring_id: mooring_id,
      from_date: from_date,
      to_date: to_date,
      boat_length: boat_length,
      boat_beam: boat_beam,
      number_of_adults: number_of_adults,
      number_of_children: number_of_children,
   };

   axios
      .get(`bookings/get_prices`, { params: object })
      .then((response) => {
         if (response.status == 200) {
            const prices = response.data;
            callback(prices);
         }
      })
      .catch((err) => {
         errorcallback(err);
         console.log('FAIL', err);
      });
};
