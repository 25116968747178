import axios from 'axios';

export const updateBookingTimes = async (
   booking_times,
   mooring_id,
   callback,
   errorcallback,
) => {
   axios
      .post(`moorings/update_times_and_prices?mooring_id=${mooring_id}`, {
         booking_times: booking_times,
      })
      .then((response) => {
         if (response.status === 200) {
            callback(response.data);
         }
      })
      .catch((err) => {
         errorcallback(err.response);
      });
};
