import moment from 'moment';
import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { withTranslation } from 'react-i18next';

import { getMyAccountData } from '../actions/account';
import { getMyMooring } from '../actions/moorings';
import bg_main from '../assets/bg_main.png';
import AlertModal from '../components/AlertModal';
import BookingCalendar from '../components/BookingCalendar';
import BookMooring from '../halfscreens/BookMooring';
import EditMooring from '../halfscreens/EditMooring';
import FindMoorings from '../halfscreens/FindMoorings';
import MaintainMoorings from '../halfscreens/MaintainMoorings';
import MaintainReservations from '../halfscreens/MaintainReservations';
import MooringDetails from '../halfscreens/MooringDetails';

const MAP_REFRESH_RATE = 30000;

class Home extends React.Component {
   constructor(props) {
      super(props);
      this.child = React.createRef();
      this.EditMooringScreen = React.createRef();
      this.MooringDetailsScreen = React.createRef();
      var today = moment().format('YYYY-MM-DD');
      var tomorrow = moment().add(1, 'day').format('YYYY-MM-DD');

      this.state = {
         loading: false,
         success: false,
         error: false,
         from_date: today,
         to_date: tomorrow,
         moorings: [],
         allMoorings: [],
         add_mooring: true,
         add_mooring_position: false,
         book_mooring: false,
         left_half_screen: 'MaintainMoorings',
         rerender: 0,
         mapCenter: { lat: 47.3769, lng: 8.5417 },
         mapZoom: 9,
         account_data: {},
      };
      this.MaintainReservations = React.createRef();
      this.AlertModal = React.createRef();
   }

   componentDidMount() {
      const authenticated = localStorage.getItem('AuthToken');
      if (!authenticated) {
         this.props.history.push('login');
      }

      getMyAccountData(
         (account_data) => {
            this.setState({ account_data: account_data });
         },
         (err) => {
            console.log('Error', err);
         },
      );

      this.interval = setInterval(() => {
         this.reloadMap();
      }, MAP_REFRESH_RATE);

      if (this.props.match.params.action) {
         const action = this.props.match.params.action;
         if (this.props.match.params.id) {
            const id = this.props.match.params.id;
            switch (action) {
               case 'editmooring':
                  getMyMooring(
                     id,
                     (mooring) => {
                        this.setState({
                           mooring: mooring,
                           left_half_screen: 'EditMooring',
                        });
                     },
                     () => {},
                  );
                  break;
               case 'mooringdetails':
                  this.showMooringDetails({ mooring_id: id }, () => {});
                  break;

               case 'reservations':
                  this.setState(
                     { left_half_screen: 'MaintainReservations' },
                     () => {
                        const from_date = moment(id);
                        this.MaintainReservations.scrollToDate(from_date);
                     },
                  );
                  break;
            }
         } else {
            // halfscreens that require no id
            switch (action) {
               case 'reservations':
                  this.setState({ left_half_screen: 'MaintainReservations' });
            }
         }
      }

      // # in URL defines the second halfscreen
      if (this.props.history.location.hash) {
         const hash = this.props.history.location.hash;
         if (hash == '#MaintainReservations') {
            this.setState({ left_half_screen: 'MaintainReservations' });
         }
      }

      // # in URL defines the second halfscreen
      // Change second halfscreen withour reloading
      this.props.history.listen((location, action) => {
         if (location.hash == '') {
            this.setState({ left_half_screen: 'MaintainMoorings' });
         }
         if (location.hash == '#MaintainReservations') {
            this.setState({ left_half_screen: 'MaintainReservations' });
         }
      });
   }

   componentDidUpdate(prevProps, prevState, snapshot) {
      console.log('Home - componentDidUpdate');
      if (prevProps.match.params.action !== this.props.match.params.action) {
         const action = this.props.match.params.action;
         if (this.props.match.params.id) {
            const id = this.props.match.params.id;
            console.log('home action', action, id);
            switch (action) {
               case 'editmooring':
                  getMyMooring(
                     id,
                     (mooring) => {
                        this.setState({
                           mooring: mooring,
                           left_half_screen: 'EditMooring',
                        });
                     },
                     () => {},
                  );
                  break;
               case 'mooringdetails':
                  this.showMooringDetails({ mooring_id: id }, () => {});
                  break;

               case 'reservations':
                  this.setState(
                     { left_half_screen: 'MaintainReservations' },
                     () => {
                        const from_date = moment(id);
                        this.MaintainReservations.scrollToDate(from_date);
                     },
                  );
                  break;
            }
         } else {
            // halfscreens that require no id
            switch (action) {
               case 'reservations':
                  this.setState({ left_half_screen: 'MaintainReservations' });
            }
         }
      }
   }

   componentWillUnmount() {
      clearInterval(this.interval);
   }

   onPinClick = (mooring) => {
      window.scrollTo(0, 0);
      this.showMooringDetails(mooring, () => {
         this.MooringDetailsScreen.loadMooring(mooring.mooring_id);
      });
   };

   showMooringDetails = (mooring, callback) => {
      this.setState(
         {
            selected_mooring_id: mooring.mooring_id,
            left_half_screen: 'MooringDetails',
         },
         () => {
            this.MooringDetailsScreen.loadMooring(mooring.mooring_id);
            callback();
         },
      );
   };

   onMapClick = () => {
      this.setState({
         mooring: null,
         left_half_screen: 'MaintainMoorings',
      });
   };

   setMapPosition = (position, zoom) => {
      // this.child.setMapPosition(position)
   };

   pointOutMooring = (mooring) => {
      this.setMapPosition(
         { lat: mooring.latitude, lng: mooring.longitude },
         10,
      );
      this.setState({ moorings: [mooring] });

      this.child.setState({ filteredMoorings: [mooring] });
   };

   onFromDateChange = (date) => {
      var to_date = this.state.to_date;
      if (moment(date) >= moment(this.state.to_date)) {
         to_date = moment(date).add(1, 'day');
         console.log('new to date', to_date);
      }
      to_date = moment(to_date).format('YYYY-MM-DD');

      this.setState({ from_date: date, to_date: to_date }, () => {
         this.reloadMap();
      });
   };
   onToDateChange = (date) => {
      this.setState({ to_date: date }, () => {
         this.reloadMap();
      });
   };
   handleAddMooring = (position) => {
      const mooring = {
         latitude: position.latitude,
         longitude: position.longitude,
         create: true,
         own: true,
         app_visible: this.state.account_data.role == 'management' ? 0 : 1,
         needs_booking_confirmation: 0,
         check_in_time: '17:00:00',
         check_out_time: '11:00:00',
         harbor_fee_included: this.state.account_data.role == 'private' ? 0 : 1,
      };
      this.setState({
         mooring: mooring,
         add_mooring: true,
         add_mooring_position: position,
         left_half_screen: 'EditMooring',
      });
   };

   changeMooringPositionClicked = (mooring) => {
      this.child.changeMooringPositionClicked(mooring.mooring_id);
   };
   changeMooringPosition = (position) => {
      const updated_mooring = {
         ...this.state.mooring,
         latitude: position.latitude,
         longitude: position.longitude,
      };
      this.setState({ mooring: updated_mooring });
      this.EditMooringScreen.changeMooringPosition(position);
      this.child.changeMooringPosition(updated_mooring);
   };

   handleCreateMooringSuccess = () => {
      this.setState({
         mooring: false,
         add_mooring: false,
         add_mooring_position: false,
         left_half_screen: 'MaintainMoorings',
      });
      this.reloadMap();
   };

   handleUpdateMooringSuccess = () => {
      const mooring_id = this.state.mooring.mooring_id;
      this.props.history.push(`/home/mooringdetails/${mooring_id}`);
   };

   handleDeleteMooringSuccess = () => {
      this.changeLeftHalfScreen('MaintainMoorings');
      this.reloadMap();
   };

   editMooringSameWindow = (mooring) => {
      this.setState({
         mooring: mooring,
         left_half_screen: 'EditMooring',
      });
      this.reloadMap();
   };

   backToMooringDetails = (mooring) => {
      this.onPinClick(mooring);
   };

   handlMooringDetailsClicked = (mooring) => {
      this.props.history.push(`/home/mooringdetails/${mooring.mooring_id}`);
   };

   editMooringClicked = (mooring) => {
      this.props.history.push(`/home/editmooring/${mooring.mooring_id}`);
   };
   mooringDetailsSameWindow = (mooring) => {
      this.setState({
         mooring: mooring,
      });

      this.changeLeftHalfScreen('MooringDetails');
      this.reloadMap();
   };

   leftScreenRouter() {
      const { left_half_screen } = this.state;

      if (left_half_screen == 'MaintainMoorings') {
         const moorings = this.state.moorings;
         return this.renderMooringList(moorings);
      }

      if (left_half_screen == 'EditMooring') {
         return this.renderEditMooring(this.state.mooring);
      }

      if (left_half_screen == 'MooringDetails') {
         return this.renderMooringDetails();
      }

      if (left_half_screen == 'MaintainReservations') {
         return this.renderMaintainReservations();
      }
   }

   changeLeftHalfScreen = (screen) => {
      console.log('Going to halfscreen', screen, this.state.mooring);
      this.setState({
         left_half_screen: screen,
      });
      return;
   };

   renderMooringList(moorings) {
      return (
         <MaintainMoorings
            onRef={(ref) => (this.MaintainMoorings = ref)}
            history={this.props.history}
            editClicked={this.editMooringClicked}
            infoClicked={this.handlMooringDetailsClicked}
            deleteSuccess={this.handleDeleteMooringSuccess}
            changeLeftHalfScreen={this.changeLeftHalfScreen}
            onAddMooring={this.addMooringPressed}
            setMapPosition={this.setMapPosition}
            pointOutMooring={this.pointOutMooring}
         />
      );
   }

   renderEditMooring(mooring) {
      return (
         <EditMooring
            onRef={(ref) => (this.EditMooringScreen = ref)}
            mooring={mooring}
            history={this.props.history}
            createSuccess={this.handleCreateMooringSuccess}
            updateSuccess={this.handleUpdateMooringSuccess}
            deleteSuccess={this.handleDeleteMooringSuccess}
            changeMooringPositionClicked={this.changeMooringPositionClicked}
            from_date={this.state.from_date}
            to_date={this.state.to_date}
            backToMooringDetails={this.backToMooringDetails}
         />
      );
   }

   renderMooringDetails() {
      return (
         <MooringDetails
            onRef={(ref) => (this.MooringDetailsScreen = ref)}
            from_date={this.state.from_date}
            to_date={this.state.to_date}
            history={this.props.history}
            rerender={this.state.rerender}
            reloadMap={this.reloadMap}
            editClicked={this.editMooringClicked}
            onFromDateChange={this.onFromDateChange}
            onToDateChange={this.onToDateChange}
            changeLeftHalfScreen={this.changeLeftHalfScreen}
            account_data={this.state.account_data}
         />
      );
   }

   renderBookMooring() {
      return (
         <BookMooring
            mooring={this.state.mooring}
            from_date={this.state.from_date}
            to_date={this.state.to_date}
            history={this.props.history}
         />
      );
   }

   renderMaintainReservations() {
      return (
         <MaintainReservations
            onRef={(ref) => {
               this.MaintainReservations = ref;
            }}
         />
      );
   }

   onReloadPress = () => {
      this.setState({ rerender: this.state.rerender + 1 });
   };

   mooringChanged = (mooring) => {
      this.setState({ mooring: mooring });
   };

   reloadMap = () => {
      this.child.reloadMap();
   };

   addMooringPressed = () => {
      this.child.addMooringPressed();
   };

   render() {
      const { t } = this.props;
      return (
         <div>
            <AlertModal onRef={(ref) => (this.AlertModal = ref)} />
            <Container style={{ backgroundColor: 'rgb(243, 243, 247, 0.99) ' }}>
               <Row>
                  <Col sm={12} md={6} lg={6} xl={4}>
                     <div style={{ height: 'calc(100vh - 150px)' }}>
                        {this.leftScreenRouter()}
                     </div>
                  </Col>
                  <Col sm={12} md={6} lg={6} xl={8}>
                     <FindMoorings
                        onRef={(ref) => (this.child = ref)}
                        onPinClick={(mooring) => {
                           this.onPinClick(mooring);
                        }}
                        onMapClick={() => this.onMapClick()}
                        addMooring={(position) => {
                           this.handleAddMooring(position);
                        }}
                        changeMooringPosition={(position) => {
                           this.changeMooringPosition(position);
                        }}
                        add_mooring_position={this.state.add_mooring_position}
                        onFromDateChange={this.onFromDateChange}
                        onToDateChange={this.onToDateChange}
                        onReloadPress={this.onReloadPress}
                        onMooringChanged={this.mooringChanged}
                        from_date={this.state.from_date}
                        to_date={this.state.to_date}
                        mapCenter={this.state.mapCenter}
                        mapZoom={this.state.mapZoom}
                        account_data={this.state.account_data}
                     />
                  </Col>
               </Row>
            </Container>
         </div>
      );
   }
}

export default withTranslation()(Home);
