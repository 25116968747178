import '../i18n';

import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { withTranslation } from 'react-i18next';

import { validateBoatForm } from '../utils/validators';
import FormInput from './FormInput';
import LoginComponent from './LoginComponent';

const i18nPrefix = 'Login';

class LoginModal extends React.Component {
   constructor(props) {
      super(props);
      this.state = {
         show: false,
         email: '',
      };
   }
   componentDidMount() {
      this.props.onRef(this);
   }
   show = (email = '') => {
      this.setState({ show: true, email: email });
   };

   handleClose = () => {
      this.setState({ show: false });
      this.props.onClose && this.props.onClose();
   };

   render() {
      const { t } = this.props;
      return (
         <>
            <Modal
               id='modal_login'
               size='md'
               show={this.state.show}
               onHide={this.handleClose}
               animation={true}>
               <Modal.Header closeButton>
                  <Modal.Title> {t(`${i18nPrefix}.login`)} </Modal.Title>
               </Modal.Header>
               <Modal.Body>
                  <LoginComponent
                     email={this.state.email}
                     onLoginSuccess={(response) => {
                        console.log('RESER', response);
                        this.handleClose();
                     }}
                  />
               </Modal.Body>
               <Modal.Footer></Modal.Footer>
            </Modal>
         </>
      );
   }
}

export default withTranslation()(LoginModal);
