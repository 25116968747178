import React from 'react';
import { Button, Modal } from 'react-bootstrap';
import { withTranslation } from 'react-i18next';

class AlertModel extends React.Component {
   constructor(props) {
      super(props);
      this.state = {
         title: '',
         text: '',
         alternativeButtonText: '',
         show: false,
      };

      this.callback = () => {};
      this.alternativeButtonCallback = () => {};
   }

   componentDidMount() {
      this.props.onRef(this);
   }

   show = (
      title,
      text,
      callback,
      alternativeButtonText,
      alternativeButtonCallback,
   ) => {
      this.setState({
         show: true,
         title: title,
         text: text,
         alternativeButtonText,
      });
      this.callback = callback;
      this.alternativeButtonCallback = alternativeButtonCallback;
   };

   render() {
      const { t } = this.props;
      return (
         <Modal
            id='modal_alert'
            show={this.state.show}
            onHide={() => this.setState({ show: false })}>
            <Modal.Header closeButton>
               <Modal.Title>{this.state.title}</Modal.Title>
            </Modal.Header>
            <Modal.Body>{this.state.text}</Modal.Body>
            <Modal.Footer>
               {this.state.alternativeButtonText ? (
                  <Button
                     style={{ marginRight: 10 }}
                     variant='outline-primary'
                     onClick={this.alternativeButtonCallback}>
                     {this.state.alternativeButtonText}
                  </Button>
               ) : null}
               <Button
                  id='button_ok'
                  variant='primary'
                  onClick={() => {
                     this.setState({ show: false });
                     this.callback();
                  }}>
                  {t(`global.ok`)}
               </Button>
            </Modal.Footer>
         </Modal>
      );
   }
}

export default withTranslation()(AlertModel);
