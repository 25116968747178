import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';

import COLORS from '../constants/colors';

export default function FormInputWrapper(props) {
   const { t, i18n } = useTranslation();
   const label = props.label || '';
   const last = !!props.last;
   const { error } = props;
   var errorMessage = '';
   if (error) {
      errorMessage = error == '*' ? '*' : t(`validators.${error}`);
   }
   return (
      <div style={{ marginBottom: 5 }}>
         <div
            style={{
               color: COLORS.MAIN_GRAY,
               alignSelf: 'center',
               fontSize: 14,
               float: 'left',
            }}>
            {label.toUpperCase()}
            {props.unit ? ` [${props.unit}]` : ''}
         </div>
         {props.type == 'file' && (
            <div
               style={{ overflow: 'hidden', height: 15, width: '100%' }}></div>
         )}
         {error && (
            <div
               style={{
                  float: 'right',
                  marginRight: 10,
                  color: COLORS.MAIN_RED,
                  alignSelf: 'center',
                  fontSize: 14,
                  left: 3,
               }}>
               {errorMessage}
            </div>
         )}
         <br></br>
         <div
            style={{
               width: '100%',
               backgroundColor: 'white',
               color: COLORS.MAIN_BLUE,
               paddingBottom: 10,
            }}>
            {props.children}
         </div>

         {!last && (
            <div
               style={{
                  width: '100%',
                  height: 1,
                  backgroundColor: COLORS.GRAY_OUTLINE,
               }}></div>
         )}
      </div>
   );
}

FormInputWrapper.propTypes = {
   label: PropTypes.string.isRequired,
};
