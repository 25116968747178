import React, { useEffect, useRef, useState } from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import { getDocuments } from '../actions/documents';
import globalStyles from '../stylesheets/globalStyles';
import type { Document } from '../types/Document';
import AddDocumentModal from './AddDocumentModal';
import AddNewButton from './AddNewButton';
import ShowDocumentModal from './ShowDocumentModal';

const i18nPrefix = 'MaintainDocuments';

type Props = {
   id_params: {
      mooring_id?: number | null;
      contact_id?: number | null;
      assigned_contact_id?: number | null;
      waiting_contact_id?: number | null;
   };
};

export default function DocumentMaintainArea(props: Props) {
   const { t } = useTranslation();
   const [documents, setDocuments] = useState([]);

   const addDocumentModal = useRef<{
      show: () => void;
   } | null>(null);

   const showDocumentModal = React.useRef<{
      show: (document: Document) => void;
   } | null>(null);

   useEffect(() => {
      reloadDocuments();
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, []);

   const onShowButtonClicked = (document: Document) => {
      if (showDocumentModal.current) {
         showDocumentModal.current.show(document);
      }
   };

   const reloadDocuments = () => {
      getDocuments(
         props.id_params,
         (documents) => {
            setDocuments(documents);
         },
         (error) => {
            console.log('error', error);
         },
         () => {},
      );
   };

   return (
      <div style={{ marginBottom: 5 }}>
         <h3>{t(`${i18nPrefix}.documents`)}</h3>
         <AddDocumentModal
            onRef={addDocumentModal}
            onClose={reloadDocuments}
            id_params={props.id_params}
         />
         <ShowDocumentModal
            onRef={showDocumentModal}
            onClose={reloadDocuments}
         />

         {documents.map((document) => {
            return (
               <div
                  key={document.id}
                  style={{
                     marginBottom: 15,
                     borderRadius: 5,
                     padding: 10,
                     borderColor: '#bbb',
                     borderStyle: 'solid',
                     borderWidth: 2,
                     fontSize: 20,
                     fontWeight: 'bold',
                  }}>
                  <Row>
                     <Col>{`${document.title}`}</Col>
                     <Col>
                        <Button
                           style={{
                              ...globalStyles.normalButton,
                              float: 'right',
                           }}
                           onClick={() => onShowButtonClicked(document)}>
                           {t(`${i18nPrefix}.show`)}
                        </Button>
                     </Col>
                  </Row>
               </div>
            );
         })}

         <AddNewButton
            center
            label={t(`${i18nPrefix}.addDocument`)}
            onClick={() => {
               addDocumentModal.current.show();
            }}
         />
      </div>
   );
}
