import React from 'react';
import { Button, Col, FormSelect, Modal, Row } from 'react-bootstrap';
import { withTranslation } from 'react-i18next';

import { sendTransferInvitation, transferMooring } from '../actions/moorings';
import BackHomeIcon from '../assets/icons/back_home_icon.png';
import FormInput from '../components/FormInput';
import COLORS from '../constants/colors';
import i18n from '../i18n';
import globalStyles from '../stylesheets/globalStyles';

class TransferMooringSection extends React.Component {
   constructor(props) {
      super(props);
      const communication_language = i18n.language;
      this.state = {
         transfer_email: null, // Value of text input if mooring has no assigned contacts
         transfer_emails: [], // values of dropdown if mooring has assigned contacts
         invitation_email_language: communication_language,
      };
      this.email = '';
   }

   componentDidMount() {
      this.email = localStorage.getItem('email');
      this.autoFillEmail();
   }

   componentDidUpdate(prevProps) {
      // only update if the landlord account has changed
      if (
         this.props.mooring.mooring_id !== prevProps.mooring.mooring_id ||
         this.props.mooring.landlord_account_id !==
            prevProps.mooring.landlord_account_id
      ) {
         this.autoFillEmail();
      }
   }

   autoFillEmail = () => {
      const { mooring } = this.props;
      if (!mooring.boatpark_account) {
         this.setState({
            transfer_emails: mooring.renters.flatMap((renter) => {
               const emails = [renter.contact.email];
               if (renter.contact.alternative_email) {
                  emails.push(renter.contact.alternative_email);
               }
               return emails;
            }),
            transfer_email:
               mooring.renters.length > 0
                  ? mooring.renters[0].contact.email
                  : null,
         });
      } else {
         this.setState({
            transfer_emails: [this.email],
            transfer_email: this.email,
         });
      }
   };

   handleTransferClick = () => {
      if (this.state.transfer_email == null || this.state.transfer_email == '')
         return; //Not empty

      transferMooring(
         this.props.mooring.mooring_id,
         this.state.transfer_email,
         (response) => {
            if (this.props.reloadMooring) this.props.reloadMooring();
         },
         (error) => {
            const errorResponse = error.response.data;
            if (errorResponse.error_code && errorResponse.error_code == 887) {
               // Boatpark account does not exist: send transfer invitation email?
               this.showTransferInvitationModal();
            } else {
               this.setState({ error: true });
            }
         },
      );
   };

   showTransferInvitationModal = () => {
      this.setState({ showTransferInvitationModal: true });
   };
   closeTransferInvitationModal = () => {
      this.setState({ showTransferInvitationModal: false });
   };
   renderTransferInvitationModal = () => {
      const { t } = this.props;
      const languages = t(`global.communicationLanguages`);
      return (
         <>
            <Modal
               show={this.state.showTransferInvitationModal}
               onHide={this.closeTransferInvitationModal}>
               <Modal.Header closeButton>
                  <Modal.Title>{t(`TransferMooring.modalTitle`)}</Modal.Title>
               </Modal.Header>
               <Modal.Body>
                  {t(`TransferMooring.modalBody`)}
                  <FormInput
                     as='select'
                     label={t(`TransferMooring.emailLanguage`)}
                     value={this.state.invitation_email_language.toUpperCase()}
                     onChange={(event) => {
                        this.setState({
                           invitation_email_language: event.target.value,
                        });
                     }}>
                     {languages.map((language, index) => (
                        <option key={index} value={language}>
                           {language}
                        </option>
                     ))}
                  </FormInput>
               </Modal.Body>
               <Modal.Footer>
                  <Button
                     variant='secondary'
                     onClick={this.closeTransferInvitationModal}>
                     {t('global.cancel')}
                  </Button>
                  <Button
                     variant='primary'
                     onClick={() => {
                        sendTransferInvitation(
                           this.props.mooring,
                           this.state.transfer_email,
                           this.state.invitation_email_language,
                           () => {},
                           () => {},
                        );
                        this.closeTransferInvitationModal();
                     }}>
                     {t(`TransferMooring.sendEmail`)}
                  </Button>
               </Modal.Footer>
            </Modal>
         </>
      );
   };

   render() {
      const { t, mooring } = this.props;

      return (
         <>
            {this.renderTransferInvitationModal()}
            <Row>
               <Col md={10}>
                  <h3> {t('EditMooring.transferMooring')}</h3>
               </Col>
               <Col md={2}>
                  <button
                     style={globalStyles.transparent}
                     onClick={() => {
                        this.setState({ transfer_email: this.email });
                     }}>
                     <img
                        style={{ width: 24, marginTop: 5, float: 'left' }}
                        onClick={this.onClickTableView}
                        src={BackHomeIcon}
                        alt='BackHomeIcon'
                     />
                  </button>
               </Col>
            </Row>

            {mooring.renters.length > 0 && (
               <FormSelect
                  style={{ marginTop: 10, marginBottom: 10 }}
                  label={t('EditMooring.transferTo')}
                  value={this.state.transfer_email}
                  onChange={(e) => {
                     this.setState({
                        transfer_email: e.target.value,
                     });
                  }}>
                  {this.state.transfer_emails.map((email, index) => {
                     return (
                        <option key={index} value={email}>
                           {email}
                        </option>
                     );
                  })}
               </FormSelect>
            )}
            {mooring.renters.length === 0 && (
               <FormInput
                  label={t('EditMooring.email')}
                  placeholder={t('EditMooring.transferEmailPlaceholder')}
                  type='text'
                  value={this.state.transfer_email}
                  onChange={(e) => {
                     this.setState({
                        transfer_email: e.target.value,
                     });
                  }}
               />
            )}

            <Button
               onClick={this.handleTransferClick}
               style={{
                  ...globalStyles.normalButton,
                  color: 'red',
                  width: '100%',
                  marginBottom: 20,
               }}>
               {t('EditMooring.transfer')}
            </Button>
         </>
      );
   }
}

export default withTranslation()(TransferMooringSection);
