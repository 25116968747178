import axios from 'axios';

export const getMyPaymentMethods = (callback, errorcallback) => {
   axios
      .get('payments/get_my_payment_methods')
      .then((response) => {
         if (response.status == 200) {
            const payment_methods = response.data;
            callback(payment_methods);
         }
      })
      .catch((err) => {
         errorcallback(err);
      });
};

export const deletePaymentMethod = (id, callback, errorcallback) => {
   axios
      .post(`payments/delete_payrexx_tokenization`, { id: id })
      .then((response) => {
         if (response.status == 200) {
            var link = response.data.link;

            console.log('link', link);

            callback(link);
         }
      });
};

export const getPayrexxTokenizationLink = (callback, errorcallback) => {
   axios
      .get('payments/get_tokenization_link')
      .then((response) => {
         if (response.status == 200) {
            var link = response.data.link;

            // console.log("link", link);

            callback(link);
         }
      })
      .catch((err) => {
         errorcallback(err);
      });
};

export const getPayrexxTransactionLink = (
   amount,
   currency,
   account_id,
   callback,
   errorcallback,
) => {
   const url = `payments/get_recharge_balance_transaction_link?amount=${amount}&currency=${currency}&account_id=${account_id}`;
   axios
      .get(url)
      .then((response) => {
         if (response.status == 200) {
            var link = response.data.link;

            console.log('link', link);

            callback(link);
         }
      })
      .catch((err) => {
         console.log('error', err);
         errorcallback(err);
      });
};

export const getPayrexxTransactionLinkWithCharge = (
   amount,
   currency,
   callback,
   errorcallback,
) => {
   console.log(
      'Url',
      `payments/get_tokenization_link_with_charge?amount=${amount}&currency=${currency}`,
   );
   axios
      .get(
         `payments/get_tokenization_link_with_charge?amount=${amount}&currency=${currency}`,
      )
      .then((response) => {
         if (response.status == 200) {
            const link = response.data.link;

            console.log('link', link);

            callback(link);
         }
      });
};

export const getPayrexxInjectionCode = (callback, errorcallback) => {
   axios.get('payments/get_payrexx_gateway_injection').then((response) => {
      if (response.status == 200) {
         var code = response.data.code;

         // console.log("link", code);

         callback(code);
      }
   });
};
