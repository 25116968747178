import axios from 'axios';

export const register = (state, boat = null, callback, errorcallback) => {
   axios
      .post('/users/register', {
         user: state,
         boat: boat,
         captchaToken: state.captchaToken,
      })
      .then((response) => {
         if (response.status == 200) {
            callback(response.data);
         }
      })
      .catch((err) => {
         errorcallback(err);
      });
};

export const completeRegistration = (accountData, callback, errorcallback) => {
   axios
      .post('/users/complete_registration', accountData)
      .then((response) => {
         if (response.status == 200) {
            callback(response.data);
         }
      })
      .catch((err) => {
         errorcallback(err);
      });
};

export const createUnregisteredAccount = (data, callback, errorcallback) => {
   axios
      .post('/users/create_unregistered_account', {
         ...data,
      })
      .then((response) => {
         if (response.status == 200) {
            callback(response.data);
         }
      })
      .catch((err) => {
         errorcallback(err);
      });
};

export const doesAccountExist = (email, callback, errorcallback) => {
   axios
      .get(`users/does_exist?email=${email}`)
      .then((response) => {
         if (response.status == 200) {
            callback(response.data.exists);
         }
      })
      .catch((err) => {
         errorcallback(err);
      });
};

export const updateAndResendActivationEmail = (
   data,
   callback,
   errorcallback,
) => {
   axios
      .post('/users/update_and_resend_email', data)
      .then((response) => {
         if (response.status == 200) {
            callback(response.data);
         }
      })
      .catch((err) => {
         errorcallback(err);
      });
};

export const resetPassword = (data, callback, errorcallback) => {
   console.log('reset password', data);
   axios
      .post('/users/reset_password', data)
      .then((response) => {
         if (response.status == 200) {
            callback(response.data);
         }
      })
      .catch((err) => {
         errorcallback(err);
      });
};
