import axios from 'axios';

export const getMyContacts = (callback, errorcallback) => {
   axios
      .get('/contacts/get_my_contacts')
      .then((response) => {
         if (response.status == 200) {
            callback(response.data);
         }
      })
      .catch((err) => {
         errorcallback(err);
      });
};

export const getContact = (id, callback, errorcallback) => {
   axios
      .get(`/contacts/get_by_id?id=${id}`)
      .then((response) => {
         if (response.status == 200) {
            callback(response.data);
         }
      })
      .catch((err) => {
         errorcallback(err);
      });
};

export function createContact(contact, callback, errorCallback) {
   axios
      .post('/contacts/add', contact)
      .then((res) => {
         const response = res.data;
         callback(response);
      })
      .catch((error) => {
         errorCallback(error);
      });
}

export function updateContact(contact, callback, errorCallback) {
   axios
      .post('/contacts/update', contact)
      .then((res) => {
         const response = res.data;
         callback(response);
      })
      .catch((error) => {
         errorCallback(error);
      });
}

export function deleteContact(id, callback, errorCallback) {
   axios
      .post('/contacts/delete', { id: id })
      .then((res) => {
         const response = res.data;
         callback(response);
      })
      .catch((error) => {
         errorCallback(error);
      });
}

export function getMyContactTypes(callback, errorcallback) {
   axios
      .get('contacts/get_my_contact_types')
      .then((response) => {
         if (response.status == 200) {
            var contact_types = response.data.contact_types;
            callback(contact_types);
         }
      })
      .catch((err) => {
         errorcallback(err.response);
      });
}

export function updateContactTypes(contact_types, callback, errorcallback) {
   axios
      .post('contacts/update_contact_types', contact_types)
      .then((response) => {
         if (response.status == 200) {
            callback();
         }
      })
      .catch((err) => {
         errorcallback(err.response);
      });
}
